<form [formGroup]="userForm" (ngSubmit)="onSubmit()" novalidate #uForm="ngForm">
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" class="form-control" id="name" formControlName="name">
              <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
                <div *ngIf="name.errors.required">
                  Name is required.
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input class="form-control" type="text" formControlName="email" id="email">
              <div *ngIf="email.invalid && (uForm.submitted || email.dirty || email.touched)" class="alert alert-danger">
                <div *ngIf="email.errors.required">
                  Email address is required.
                </div>
              </div>
            </div>
            <div class="form-group">
              <input type="checkbox" id="isAdmin" name="isAdmin" formControlName="isAdmin" /> Admin account
            </div>
            <div class="form-group">
              <input type="checkbox" id="notificationsEnabled" name="notificationsEnabled" formControlName="notificationsEnabled" />Notifications enable
            </div>
            <!--<div class="form-group">-->
            <!--<input type="checkbox" id="isActive" name="isActive" formControlName="isActive" /> Active-->
            <!--</div>-->
          </div>
        </div>
        <div class="card">

          <div class="card-footer">
            <button type="reset" class="btn btn-sm btn-danger" (click)="onCancel()">
              <i class="fa fa-ban"></i> cancel</button>
            <button type="submit" class="btn btn-sm btn-primary">
              <i class="fa fa-dot-circle-o"></i> Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>