import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class ErrorService {

  constructor(private toastr: ToastrService, private router: Router, private spinnerService: NgxSpinnerService) {
  }

  public showInvalidCredentials() {
    this.toastr.warning('Please log in again.', '');
    ;
    this.router.navigate(['./pages/login']);
  }

  public showInternalServerError() {
    this.toastr.error('Internal server error', 'Please try again!');
    this.spinnerService.hide();
  }

  public showInvalidDataServerError(error: HttpErrorResponse) {
    this.toastr.error(error.error.error, 'Server refuse your request');
    this.spinnerService.hide();
  }
  public showForbiddenError(error: HttpErrorResponse) {
    this.toastr.error('Operation forbidden. Permission denied', 'Server refuse your request');
    this.spinnerService.hide();
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this.showInvalidCredentials();
        } else if (error.status === 422) {
          this.showInvalidDataServerError(error);
        } else if (error.status === 403) {
          this.showForbiddenError(error);
        } else {
          this.showInternalServerError();
        }
      }

      // Let the app keep running by returning an empty result.
      return Observable.throw(error);
    };
  }
}
