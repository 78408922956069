import {Pipe} from '@angular/core';
import {CategoryConstantService} from './categories/category-constant.service';


@Pipe({
  name: 'advanceTemplate'
})
export class AdvanceTemplatePipe {

  constructor(private categoryConstantService: CategoryConstantService) {

  }

  transform(value: number, fallback: string): boolean {
    return value === this.categoryConstantService.PROFILE || value === this.categoryConstantService.LED_FIXTURES;
  }

}
