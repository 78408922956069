import { NgModule } from '@angular/core';
import { ConfiguratorsRoutingModule } from './configurators-routing.module';
import { ConfiguratorsListComponent } from './configurators-list/configurators-list.component';
import { EditConfiguratorComponent } from './configurator-edit/edit-configurator.component';
import { ConfiguratorService } from './configurator.service';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {TinyMceModule} from 'angular-tinymce';
import {environment} from '../../../environments/environment';
import { SharedModule } from '../shared/shared.module';
export function uploaderHandler(blobInfo, success, failure) {

  const token = JSON.parse(localStorage.getItem('current_klus_us_user'));

  let xhr, formData;
  xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  xhr.open('POST',  environment.apiUrl + '/uploader');
  xhr.setRequestHeader('Authorization', 'Bearer ' + token.access_token);
  xhr.onload = function() {
    let json;

    if (xhr.status !== 200) {
      failure('HTTP Error: ' + xhr.status);
      return;
    }

    json = JSON.parse(xhr.responseText);

    if (!json || typeof json.location !== 'string') {
      failure('Invalid JSON: ' + xhr.responseText);
      return;
    }

    success(json.location);
  };
  formData = new FormData();
  formData.append('file', blobInfo.blob(), blobInfo.filename());

  xhr.send(formData);
}
@NgModule({
  imports: [
    ConfiguratorsRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TinyMceModule.forRoot({
      baseURL : '/assets/tinymce',
      skin_url : '/assets/tinymce/skins/lightgray',
      theme_url : '/assets/tinymce/themes/modern/theme.min.js',
      tinymceScriptURL : '/assets/tinymce/tinymce.min.js',
      menubar: false,
      toolbar_items_size: 'small',
      autoresize_max_height: 60,
      autoresize_min_height: 50,
      // file_picker_types: 'file image media',
      // file_picker_callback: filePickerCallback,
      image_advtab: true,
      images_upload_url: environment.apiUrl + '/uploader',
      images_upload_base_path: '',
      verify_html: false,
      relative_urls : false,
      // images_upload_credentials: true,
      // images_reuse_filename: true,
      images_upload_handler: uploaderHandler,
      toolbar1: 'bold italic underline strikethrough |styleselect formatselect fontselect fontsizeselect | image code',
      toolbar2: 'cut copy paste | searchreplace | bullist numlist | outdent indent blockquote | undo redo | link unlink ',
      plugins: ['autoresize link image lists table code'],
      valid_children : '+body[style]'
    })
  ],
  declarations: [ConfiguratorsListComponent, EditConfiguratorComponent],
  entryComponents: [
  ],
  providers: [
    ConfiguratorService, DatePipe
  ]
})
export class ConfiguratorsModule { }