import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AutocompleteProduct, ChildEditProduct} from 'app/views/products/edit-product/edit-product.model';
import {ProductService} from 'app/views/products/api/product.service';
import {Observable} from 'rxjs/Observable';
import {ToastrService} from 'ngx-toastr';
import {startWith} from 'rxjs/operators/startWith';
import {map} from 'rxjs/operators/map';
import {CategoryConstantService} from '../../categories/category-constant.service';
import {Helpers} from '../../../../helpers/helpers.service';
import {UserService} from '../../../../user/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'edit-product-related-products',
  templateUrl: './edit-product-related-products.component.html',
  styleUrls: ['./edit-product-related-products.component.scss']
})
export class EditProductRelatedProductsComponent implements OnInit {

  @Input()
  productId: number;

  @Input()
  productName: string;

  @Input()
  productParentId: number;

  @Input()
  productCategoryId: number;

  @Input()
  childs: ChildEditProduct[];

  @ViewChild('extraRelationInfoTemplate') extraRelationInfoTemplate: TemplateRef<any>;

  extraRelationInfoForm: FormGroup;
  productsForRelation: AutocompleteProduct[] = new Array<AutocompleteProduct>();
  autocompleteControl: FormControl = new FormControl();
  prentAutocompleteControl: FormControl = new FormControl();
  filteredOptions: Observable<AutocompleteProduct[]>;
  modalRef: BsModalRef;
  childId: number;
  parentId: number;
  parents: ChildEditProduct[];
  updateRelation = false;
  helpers: any;
  coversIds: number[];
  bindingAngleValues = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180];

  constructor(private fb: FormBuilder, private productService: ProductService, private toastr: ToastrService,
              private modalService: BsModalService, private categoryConstants: CategoryConstantService) {
    this.createForm();
    this.helpers = new Helpers();
  }

  ngOnInit() {
    this.getProductsForRelation();
    this.getAllCoversIds();
   this.getParents();
  }

  getParents() {
    this.productService.getParents(this.productId).subscribe(parents => {
      this.parents = parents;
    });
  }

  getProductsForRelation() {
    if (!this.productId) {
      return;
    }
    this.productService.getRelationBasicList().subscribe(products => {
        this.productsForRelation.length = 0;
        for (const product of products) {
          this.productsForRelation.push(new AutocompleteProduct(product.id, product.name, product.catalogueNumber, product.categoryId));
        }
        this.filteredOptions = this.autocompleteControl.valueChanges
          .pipe(
            startWith<string | AutocompleteProduct>(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this.filter(name) : this.productsForRelation.slice()));
      }
    );
  }

  getAllCoversIds() {
    this.coversIds = [this.categoryConstants.COVERS];
    this.productService.getAllCategoryChildIds(this.categoryConstants.COVERS).subscribe(childIds => {
      this.coversIds.push(...childIds);
    });
  }

  filter(name: string): AutocompleteProduct[] {
    return this.productsForRelation.filter(option =>
      (option.name + option.catalogueNumber).toLowerCase().indexOf(name.toLowerCase()) !== -1);
  }

  displayFn(product?: AutocompleteProduct): string | undefined {
    return product ? product.name : undefined;
  }

  onAutocompleteFormSubmit() {
  }

  autocompleteOptionSelected(autocompleteProduct: AutocompleteProduct) {
    if (this.isExtraRelationInfoProduct(autocompleteProduct.categoryId)) {
      this.childId = autocompleteProduct.id;
      this.openExtraRelationInfoModal(this.extraRelationInfoTemplate);
    } else {
      this.addRelation(this.productId, autocompleteProduct.id, null, true);
    }
  }

  autocompleteParentOptionSelected(autocompleteProduct: AutocompleteProduct) {
    if (this.isExtraRelationInfoProduct(autocompleteProduct.categoryId)) {
      this.parentId = autocompleteProduct.id;
      this.openExtraRelationInfoModal(this.extraRelationInfoTemplate);
    } else {
      this.addRelation(autocompleteProduct.id, this.productId);
    }
  }

  confirmDelete(childId: number) {
    this.productService.removeRelation(this.productId, childId).subscribe(product => {
      this.childs = product.data.childs.data;
      this.cleanUp();
      this.getProductsForRelation();
      this.reloadHistory();
    });
  }
  confirmDeleteParent(parentId: number) {
    this.productService.removeRelation(parentId, this.productId).subscribe(product => {
      this.parents = this.parents.filter(p => p.id !== parentId);
      this.cleanUp();
      this.reloadHistory();
    });
  }

  declineDelete(): void {
    this.cleanUp();
  }

  openExtraRelationInfoModal(template: TemplateRef<any>, mutual?: boolean) {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  confirmExtraRelationInfo() {
    const bindingAngle = + this.extraRelationInfoForm.value.bindingAngle;
    if (this.updateRelation) {
      this.productService.updateRelation(this.productId, this.childId, bindingAngle).subscribe(product => {
        this.cleanUp();
        this.childs = product.data.childs.data;
        this.getParents();
        this.toastr.success('Related product added successfully');
        this.reloadHistory();
      });
    } else {
      if (this.childId) {
        this.addRelation(this.productId, this.childId, bindingAngle, true);
      } else {
        this.addRelation(this.parentId, this.productId, bindingAngle);
      }
    }
  }

  declineExtraRelationInfo(): void {
    this.cleanUp();
  }

  isExtraRelationInfoProduct(childProductCategoryId: number) {
    return (
      (this.productParentId === this.categoryConstants.PROFILE && this.coversIds.indexOf(childProductCategoryId) !== -1) ||
      (this.coversIds.indexOf(this.productParentId) !== -1 && childProductCategoryId == this.categoryConstants.PROFILE)
    );
  }

  createForm() {
    this.extraRelationInfoForm = this.fb.group({
      bindingAngle: ['']
    });
  }

  addRelation(productId: number, relatedProductId: number, bindingAngle?: number, mutual?: boolean) {
    this.productService.addRelation(productId, relatedProductId, bindingAngle, mutual).subscribe(product => {
      this.cleanUp();
      if (productId === this.productId) {
        this.childs = product.data.childs.data;
        this.getParents();
      } else {
        this.getParents();
      }
      this.toastr.success('Related product added successfully');
      this.reloadHistory();
    });
  }

  cleanUp() {
    this.childId = null;
    this.parentId = null;
    this.updateRelation = false;
    this.autocompleteControl.patchValue('');
    this.extraRelationInfoForm.setValue({
      bindingAngle: ''
    });
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  isValidForm() {
    return this.extraRelationInfoForm.status === 'VALID';
  }

  edit(id: number) {
    this.updateRelation  = true;
    this.childId = id;
    this.openExtraRelationInfoModal(this.extraRelationInfoTemplate);
  }

  reloadHistory() {
    this.productService.getProduct(this.productId).subscribe(
      product => {
        this.helpers.setHistoryRecords(product);
      });
    }

}
