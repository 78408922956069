import { ApiCategory } from 'app/views/products/api/api-category-responses.model';
import { ApiItems } from './api-features-responses.model';
import { ChildEditProduct } from '../edit-product/edit-product.model';


export class ApiGetProductsResponse {
    constructor(public data: ApiProduct[]) {
    }
}

export class ApiCopyProductResponse {
    constructor(public newProductId: number) {
    }
}

export class ApiGetProductsBasicResponse {
    constructor(public data: ApiProductBasic[]) {
    }
}

export class ApiGetProductResponse {
    constructor(public data: ApiProduct) {
    }
}

export class ApiProduct {
    constructor(public id: number,
        public name: string,
        public description: string,
        public info: string,
        public shortName: string,
        public assets: string,
        public additionalInfo: string,
        public assemblyDescription: string,
        public catalogueNumber: string,
        public productGuid: string,
        public categories: ApiProductCategories,
        public category: ApiProductCategory,
        public promotedUntil: string,
        public modifiedOn: string,
        public createdOn: string,
        public isActive: boolean,
        public isVirtualProduct: boolean,
        public isVisibleOnList: boolean,
        public inVisibleOnSearch: boolean,
        public sortOrder: number,
        public documents: ApiProductDocuments,
        public featureItems: ApiItems,
        public specificationRecords: ApiProductSpecificationRecords,
        public images: ApiProductImages,
        public childs: ApiChilds,
        public attribs: ApiAttribs,
        public bindingAngle: number,
        public image: string,
        public instructional_video_link: string,
        public instructional_video_link_2: string,
        public product_card_gen_prevent: boolean,
        public historyRecords: any
        ) {
    }
}
export class ApiProductBasic {
    constructor(public id: number,
        public name: string,
        public catalogueNumber: string,
        public category: string,
        public categoryId: number,
        public modifiedOn: string,
        public modifiedBy: string,
        public createdOn: string,
        public isActive: boolean,
        public sortOrder: number) {
    }
}

export class ApiProductCategories {
    constructor(public data: ApiCategory[]) {
    }
}
export class ApiProductCategory {
    constructor(public data: ApiCategory) {
    }
}

export class ApiProductImages {
    constructor(public data: ApiImage[]) {
    }
}

export class ApiImage {
    constructor(public id: number,
        public createdOn: string,
        public deletedOn: string,
        public modifiedOn: string,
        public directoryGuid: string,
        public filename: string,
        public name: string,
        public thumbFilename: string,
        public galleryTypeId: number,
        public sortOrder: number) {
    }
}

export class ApiProductDocuments {
    constructor(public data: ApiDocument[]) {
    }
}

export class ApiDocument {
    constructor(public id: number,
        public createdOn: string,
        public deletedOn: string,
        public modifiedOn: string,
        public directoryGuid: string,
        public fileName: string,
        public name: string,
        public languageId: number,
        public sortOrder: number,
        public thumbFilename: string) {
    }
}

export class ApiUpdateImageResponse {
    constructor(public id: number,
        public product_id: number,
        public filename: string,
        public name: string,
        public thumbFilename: string,
        public directoryGuid: string,
        public sortOrder: number,
        public galleryTypeId: number,
        public created_at: string,
        public updated_at: string,
        public deleted_at: string) {
    }
}

export class ApiProductSpecificationRecords {
    constructor(public data: ApiProductSpecification[]) {
    }
}
export class ApiProductSpecification {
    constructor(public id: number,
        public colorId: number,
        public color: string,
        public refNumber: string,
        public availableLengths: string,
        public sortOrder: number
        ) {
    }
}

export class ApiChilds {
    constructor(public data: ApiProduct[]) {
    }
}
export class ApiParents {
    constructor(public data: ChildEditProduct[]) {
    }
}

export class ApiAttribs {
    constructor(public data: ApiAttribute[]) {
    }
}

export class ApiAttribute {
    constructor(public id: number,
        public description: string) {
    }
}

export class ApiAttributeData {
    constructor(public data: ApiAttribute) {
    }
}
