export class ListProduct {
  constructor(public id: number,
    public name: string,
    public catalogueNumber: string,
    public category: string,
    public categoryId: number,
    public modifiedOn: string,
    public modifiedBy: string,
    public createdOn: string,
    public isActive: boolean,
    public sortOrder: number) {
  }
}