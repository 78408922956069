import { RegionList } from './../Distributor.model';
import {merge as observableMerge, fromEvent as observableFromEvent,  Observable, BehaviorSubject } from 'rxjs';

import {map, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'app/user/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { NgxSpinnerService } from 'ngx-spinner';
import { DistributorService } from '../distributor.service';

@Component({
  selector: 'regions-list',
  templateUrl: './regions-list.component.html',
  styleUrls: ['./regions-list.component.scss']
})
export class RegionsListComponent implements OnInit {

  displayedColumns = ['id','title','created_at', 'updated_at', 'actions'];
  dataSource: RegionSource | null;
  database: RegionDatabase;
  modalRef: BsModalRef;
  regionId: number;
  typeFilterActive = false;

  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) public sort: MatSort;
  @ViewChild('filter', { static: true }) public filter: ElementRef;

  constructor(private toastr: ToastrService, private router: Router,
    private distributorService: DistributorService, private modalService: BsModalService
    , private spinnerService: NgxSpinnerService, private userService: UserService) {
  }

  ngOnInit(): void {
    this.spinnerService.show();

    this.database = new RegionDatabase(this.distributorService, this.spinnerService);
    this.dataSource = new RegionSource(this.database, this.paginator, this.sort)
    observableFromEvent(this.filter.nativeElement, 'keyup').pipe(
      debounceTime(150),
      distinctUntilChanged(),)
      .subscribe(() => {
        if (!this.dataSource) { return; }
        this.dataSource.filter = this.filter.nativeElement.value;
      });
  }


  redirectToEdit(regionId) {
    this.router.navigate(['./distributors/region/edit/' + regionId]);
  }

  isAdmin(): boolean {
    return this.userService.isAdmin();
  }

  onTypeChange(typeId) {
    const test = Number(typeId);
    this.dataSource.typeFilter = test;
    this.typeFilterActive = this.dataSource.typeFilter !== -1;
  }

  openModal(template: TemplateRef<any>, regionId: number) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.regionId = regionId;
  }

  confirm(): void {
    this.spinnerService.show();
    this.distributorService.deleteRegion(this.regionId)
      .subscribe(
        response => {
          this.toastr.success('Region removed successfully', '');
          this.deleteRowDataTable(this.regionId);
          this.spinnerService.hide();
        });
    this.modalRef.hide();
  }

  decline(): void {
    this.regionId = null;
    this.modalRef.hide();
  }


  private deleteRowDataTable(itemId) {
    const dsData = this.database.data;
    const itemIndex = dsData.findIndex(obj => obj['id'] === itemId);
    dsData.splice(itemIndex, 1)
    this.database.dataChange.next(dsData);
  }
}

export class RegionDatabase {
  /** Stream that emits whenever the data has been modified. */
  dataChange: BehaviorSubject<RegionList[]> = new BehaviorSubject<RegionList[]>([]);
  get data(): RegionList[] { return this.dataChange.value; }

  constructor(private distributorService: DistributorService, spinner: NgxSpinnerService) {
    distributorService.getAllRegions().subscribe(
      resp => {
        this.dataChange.next(resp);
        spinner.hide();
      });
  }
}


export class RegionSource extends DataSource<RegionList> {
  _filterChange = new BehaviorSubject('');
  get filter(): string { return this._filterChange.value; }
  set filter(filter: string) { this._filterChange.next(filter); }
  _typeFilterChange = new BehaviorSubject(-1);

  get typeFilter(): number {
    return this._typeFilterChange.value;
  }

  set typeFilter(typeId: number) {
    this._typeFilterChange.next(typeId);
  }
  prevFilterValue: string;

  constructor(private _database: RegionDatabase, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this.prevFilterValue = this.filter.toLowerCase();
  }

  connect(collectionViewer: CollectionViewer): Observable<RegionList[]> {
    const displayDataChanges = [
      this._database.dataChange,
      this._filterChange,
      this._sort.sortChange,
      this._typeFilterChange,
      this._paginator.page
    ];
    return observableMerge(...displayDataChanges).pipe(map(() => {

      const data = this._database.data.slice().filter((item: RegionList) => {
        if (item.title == null) {
          return false;
        }
        const searchStr = (item.title).toLowerCase();
        const searchResult = searchStr.indexOf(this.filter.toLowerCase()) !== -1;
        return searchResult;
      });

      if (this.filter.toLowerCase() !== this.prevFilterValue) {
        this._paginator.pageIndex = 0;
      }
      this.prevFilterValue = this.filter.toLowerCase();

      // Sort filtered data
      const sortedData = this.sortData(data.slice());

      // Grab the page's slice of data.
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return sortedData.splice(startIndex, this._paginator.pageSize);
    }));
  }

  disconnect(collectionViewer: CollectionViewer): void {
  }
  /** Returns a sorted copy of the database data. */
  sortData(data: RegionList[]): RegionList[] {
    if (!this._sort.active || this._sort.direction === '') { return data; }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'id': [propertyA, propertyB] = [a.id, b.id]; break;
        case 'title': [propertyA, propertyB] = [a.title, b.title]; break;
        case 'created_at': [propertyA, propertyB] = [a.created_at, b.created_at]; break;
        case 'updated_at': [propertyA, propertyB] = [a.updated_at, b.updated_at]; break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }
}

