import { NgModule } from '@angular/core';
import { LumenOutputRangeRoutingModule } from './lumen-output-range-routing.module';
import { LumenOutputRangeListComponent } from './lumen-output-range-list/lumen-output-range-list.component';
import { EditLumenOutputRangeComponent } from './lumen-output-range-edit/edit-lumen-output-range.component';
import { LumenOutputRangeService } from './lumen-output-range.service';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {TinyMceModule} from 'angular-tinymce';
import {environment} from '../../../environments/environment';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    LumenOutputRangeRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TinyMceModule.forRoot({
      baseURL : '/assets/tinymce',
      skin_url : '/assets/tinymce/skins/lightgray',
      theme_url : '/assets/tinymce/themes/modern/theme.min.js',
      tinymceScriptURL : '/assets/tinymce/tinymce.min.js',
      menubar: false,
      toolbar_items_size: 'small',
      autoresize_max_height: 60,
      autoresize_min_height: 50,
      // file_picker_types: 'file image media',
      // file_picker_callback: filePickerCallback,
      image_advtab: true,
      images_upload_url: environment.apiUrl + '/uploader',
      images_upload_base_path: '',
      verify_html: false,
      relative_urls : false,
      // images_upload_credentials: true,
      // images_reuse_filename: true,
      // images_upload_handler: uploaderHandler,
      toolbar1: 'bold italic underline strikethrough |styleselect formatselect fontselect fontsizeselect | image code',
      toolbar2: 'cut copy paste | searchreplace | bullist numlist | outdent indent blockquote | undo redo | link unlink ',
      plugins: ['autoresize link image lists table code'],
      valid_children : '+body[style]'
    })
  ],
  declarations: [LumenOutputRangeListComponent, EditLumenOutputRangeComponent],
  entryComponents: [
  ],
  providers: [
    LumenOutputRangeService, DatePipe
  ]
})
export class LumenOutputRangeModule { }
