<header class="app-header navbar navbar-{{user.language | lowercase}}">
  <button class="navbar-toggler d-lg-none" type="button" appMobileSidebarToggler>&#9776;</button>
  <a class="navbar-brand" href="#"></a>
  <button class="navbar-toggler d-md-down-none" type="button" appSidebarMinimizer>&#9776;</button>
  <!-- <ul class="nav navbar-nav d-md-down-none">
    <li class="nav-item px-3">
      <a class="nav-link" href="#">Dashboard</a>
    </li>
    <li class="nav-item px-3">
      <a class="nav-link" href="#">User settings</a>
    </li>
  </ul> -->

  <ul class="nav navbar-nav  ml-auto">
    <li class="nav-item" dropdown placement="bottom right">
      <div *ngIf="user && user.language" class="nav-link"><app-user-settings></app-user-settings> </div>
    </li>
    <li class="nav-item dropdown" dropdown placement="bottom right" style="padding-right:20px">
      <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
        dropdownToggle (click)="false">
        <span class="d-md-down-none">
          <i class="fa fa-user mr-1"></i>
          <strong>{{user.name}}&nbsp;</strong>
        </span>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center">
          <strong>Actions</strong>
        </div>
        <a class="dropdown-item" href="#" (click)="openModal(passwordChangeTemplate)">
          <i class="fa fa-key"></i> Change password</a>
        <a class="dropdown-item" href="#" (click)="logout()">
          <i class="fa fa-lock"></i> Logout</a>
      </div>
    </li>
  </ul>
</header>

<ng-template #passwordChangeTemplate>
  <form [formGroup]="changePasswordForm" (ngSubmit)="confirmChange()" novalidate #valueForm="ngForm">
    <div class="modal-header">
      <h4 class="modal-title">Enter new password</h4>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" align="right" formControlName="password" id="password">
        <div *ngIf="password.invalid && (valueForm.submitted || password.dirty || password.touched)" class="alert alert-danger">
          <div *ngIf="password.errors.required">
            Password is  required.
          </div>
          <div *ngIf="password.errors.minlength">
            Password must contain min 8 characters
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="modalRef.hide()">Cancel</button>
      <button type="submit" class="btn btn-primary">Save</button>
    </div>
  </form>
</ng-template>
