

<li *ngIf="user" class="nav-item dropdown pr-4" dropdown placement="bottom right">
  <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
     dropdownToggle (click)="false">
    <span class="d-md-down-none mr-2">
      <strong>content language:</strong>
    </span>
    <img src="/assets/img/flags/{{user.language | lowercase}}.svg" width="30px">
  </a>
  <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
    <button class="dropdown-item" href="" (click)="setLanguage(1)" >
      <i class="fa fa-globe"></i> en
    </button>
    <button class="dropdown-item" href="" (click)="setLanguage(4)" >
      <i class="fa fa-globe"></i> es
    </button>
  </div>
</li>