<form [formGroup]="categoryForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="animated fadeIn">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group">
                            <label for="name">Name</label>
                            <input type="text" class="form-control" id="name" formControlName="name">
                            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
                                <div *ngIf="name.errors.required">
                                    Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="info">Description</label>
                            <angular-tinymce  formControlName='description'></angular-tinymce>
                        </div>
                        <div class="form-group">
                            <label for="info">Parent category</label>
                            <select class="form-control" formControlName="parentCategoryId">
                                <option value="">None</option>
                                <option *ngFor="let category of orderedCategories" [value]="category.id">{{category.name}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <input type="checkbox" id="isActive" name="isActive" formControlName="isActive" /> Active
                        </div>
                    </div>
                </div>
                <div class="card">

                    <div class="card-footer">
                        <button type="submit" class="btn btn-sm btn-primary mr-1" [disabled]="!isValidForm()">
                            <i class="fa fa-dot-circle-o"></i> Submit</button>
                        <button type="reset" class="btn btn-sm btn-danger" (click)="onReset()">
                            <i class="fa fa-ban"></i> Reset</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>