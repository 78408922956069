import { AfterViewInit, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { EditProductImage } from 'app/views/products/edit-product/edit-product.model';
import { FineUploader } from 'fine-uploader';
import { ProductService } from 'app/views/products/api/product.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../../environments/environment';
import { UserService } from '../../../../user/user.service';
import { User } from '../../../../user/user.model';
import { AuthenticationService } from '../../../../auth/authentication.service';
import {Helpers} from '../../../../helpers/helpers.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'edit-product-album-images',
  templateUrl: './edit-product-album-images.component.html',
  styleUrls: ['./edit-product-album-images.component.scss']
})
export class EditProductAlbumImagesComponent implements OnInit, AfterViewInit {

  @Input()
  divId: string;

  @Input()
  fileType: number;

  @Input()
  images: EditProductImage[];

  @Input()
  productId: number;

  @Input()
  productCard: boolean;

  user: User;
  editFileForm: FormGroup;
  uploader: FineUploader;
  imagesAllowedExtensions = ['jpeg', 'jpg', 'gif', 'png'];
  documentsAllowedExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'txt', 'rtf', 'html', 'zip', 'rar', 'svg'];
  directoryGuid;
  modalRef: BsModalRef;
  fileId: number;
  fileName: string;
  helpers:any;	
  product:any;

  constructor(private productService: ProductService, private userService: UserService, private modalService: BsModalService,
    private toastr: ToastrService, private fb: FormBuilder, private spinnerService: NgxSpinnerService,
    private authService: AuthenticationService) {

    this.editFileForm = this.fb.group({
      name: ['', Validators.required]
    });

    this.helpers = new Helpers();
  }

  ngOnInit(): void {
    this.reloadLastModifieds();
  }

  ngAfterViewInit() {
    this.getInitialData();
  }

  getInitialData() {
    this.userService.getUser().subscribe(user => {
      this.user = user;
      this.uploader = this.configureUploader(this.divId, this);
    })
  }

  configureUploader(elementId: string, that: any) {
    return new FineUploader({
      element: document.getElementById(elementId),
      autoUpload: true,
      request: {
        endpoint: environment.apiUrl + '/uploader?fileType=' + that.fileType,
        customHeaders: {
          'Authorization': `Bearer ${that.authService.getToken()}`
        }
      },
      validation: {
        allowedExtensions: that.imagesAllowedExtensions.concat(that.documentsAllowedExtensions)
      },

      callbacks: {
        onSubmit: function (options) {
          this.setParams({ productId: that.productId, fileType: that.fileType, languageId: that.user.languageId });
          that.spinnerService.show();
        },
        onAllComplete: function (succeeded, failed) {
          that.productService.getProduct(that.productId).subscribe(
            product => {
              switch (parseInt(that.fileType)) {
                case 1:
                  that.images = product.productImages;
                  break;
                case 2:
                  that.images = product.usageImages;
                  break;
                case 3:
                  that.images = product.documents;
                  break;
                case 4:
                  that.images = product.technicalDrawingImages;
                  break;
                default:
                  break;
              }
              that.helpers.setHistoryRecords(product);
              this.reset();
              that.spinnerService.hide();
            });
        }
      }
    });
  }

  openDeleteModal(template: TemplateRef<any>, directoryGuid: string) {
    this.directoryGuid = directoryGuid;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  declineDelete(): void {
    this.modalRef.hide();
    this.directoryGuid = '';
  }

  confirmDelete(): void {
    this.productService.deleteFile(this.directoryGuid, this.fileType)
      .subscribe(
        response => {
          if (response.success === true) {
            this.toastr.success('Image removed successfuly', '');
            this.images = this.images.filter(item => item.directoryGuid !== this.directoryGuid);

            this.productService.getProduct(this.productId).subscribe(
              product => {
                this.helpers.setHistoryRecords(product);
                this.spinnerService.hide();
              });


          } else {
            this.toastr.error('Internal server error', '');
          }
          this.directoryGuid = '';
        },
        error => {
        },
        () => {

        });

    this.modalRef.hide();
  }

  openEditModal(template: TemplateRef<any>, fileId: number, fileName: string) {
    this.fileId = fileId;
    this.fileName = fileName;

    this.editFileForm.setValue({
      name: this.fileName
    });
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  confirmEdit(): void {
    this.fileName = this.editFileForm.value.name;
    this.productService.updateFile(this.fileId, this.fileType, this.fileName)
      .subscribe(
        response => {
          this.toastr.success('File renamed successfuly', '');
          this.images.find(item => item.id === this.fileId).name = this.fileName;
          this.fileId = null;
          this.fileName = null;
          this.productService.getProduct(this.productId).subscribe(
		            product => {
                this.helpers.setHistoryRecords(product);
                this.spinnerService.hide();
  
            });
  
            
        },
        error => {
        },
        () => {

        });

    this.modalRef.hide();
  }

  declineEdit(): void {
    this.modalRef.hide();
    this.fileId = null;
    this.fileName = null;
  }

  moveBefore(index: number) {
    if (Number(this.fileType) === 3) {
      return this.moveDocumentBefore(index);
    }

    if (index !== 0) {
      this.spinnerService.show();
      const image = this.images[index];
      const reference = this.images[index - 1];
      this.productService.imageMoveBefore(image.id, reference.id,this.fileType).subscribe(data => {
        this.images = data;
        this.spinnerService.hide();
        this.reloadLastModifieds();
      });
    }
  }

  moveAfter(index: number) {
    if (Number(this.fileType) === 3) {
      return this.moveDocumentAfter(index);
    }
    if (index + 1 < this.images.length) {
      this.spinnerService.show();
      const image = this.images[index];
      const reference = this.images[index + 1];
      this.productService.imageMoveAfter(image.id, reference.id, this.fileType).subscribe(data => {
        this.images = data;
        this.spinnerService.hide();
        this.reloadLastModifieds();
      });
    }
  }

  moveDocumentBefore(index: number) {
    if (index !== 0) {
      this.spinnerService.show();
      const image = this.images[index];
      const reference = this.images[index - 1];
      this.productService.documentMoveBefore(image.id, reference.id).subscribe(data => {
        this.images = data;
        this.spinnerService.hide();
        this.reloadLastModifieds();
      });
    }
  }

  moveDocumentAfter(index: number) {
    if (index + 1 < this.images.length) {
      this.spinnerService.show();
      const image = this.images[index];
      const reference = this.images[index + 1];
      this.productService.documentMoveAfter(image.id, reference.id).subscribe(data => {
        this.images = data;
        this.spinnerService.hide();
        this.reloadLastModifieds();
      });
    }
  }

  preventProductCardGenerationChange(event) {
    this.productService.preventProductCardGeneration(this.productId, !event.target.checked).subscribe(()=>{
      this.spinnerService.hide();
      this.reloadLastModifieds();
    });
  }
 
  reloadLastModifieds(){
    this.productService.getProduct(this.productId).subscribe(
      product => {
        this.helpers.setHistoryRecords(product);
        this.spinnerService.hide();
      });
  }
}
