import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {ProductListComponent} from './product-list/product-list.component';
import {AddProductComponent} from './add-product/add-product.component';
import {EditProductComponent} from './edit-product/edit-product.component';

import {CategoryListComponent} from './categories/category-list/category-list.component';
import {EditCategoryComponent} from './categories/edit-category/edit-category.component';
import {AddCategoryComponent} from './categories/add-category/add-category.component';

import {FeatureListComponent} from './features/feature-list/feature-list.component';
import {EditFeatureComponent} from './features/edit-feature/edit-feature.component';
import {AddFeatureComponent} from './features/add-feature/add-feature.component';
import {ProductsComponent} from './products/products.component';

const routes: Routes = [
  {
    path: '',
    component: ProductsComponent,
    data: {
      title: 'Products'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list'
      },
      {
        path: 'list',
        component: ProductListComponent,
        data: {
          title: 'Products List'
        }
      },
      {
        path: 'add',
        component: AddProductComponent,
        data: {
          title: 'Add Product'
        }
      },
      {
        path: 'edit/:id',
        component: EditProductComponent,
        data: {
          title: 'Edit Product'
        }
      },
      {
        path: 'categories',
        data: {
          title: 'Categories'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'list'
          },
          {
            path: 'list',
            component: CategoryListComponent,
            data: {
              title: 'Categories List'
            }
          },
          {
            path: 'add',
            component: AddCategoryComponent,
            data: {
              title: 'Add Category'
            }
          },
          {
            path: 'edit/:id',
            component: EditCategoryComponent,
            data: {
              title: 'Edit Category'
            }
          },
        ]
      },
      {
        path: 'features',
        data: {
          title: 'Features'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'list'
          },
          {
            path: 'list',
            component: FeatureListComponent,
            data: {
              title: 'Features List'
            }
          },
          {
            path: 'add',
            component: AddFeatureComponent,
            data: {
              title: 'Add Feature'
            }
          },
          {
            path: 'edit/:id',
            component: EditFeatureComponent,
            data: {
              title: 'Edit Feature'
            }
          },
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule {}
