export class ApiGetNewsResponse {
  constructor(public data) {
  }
  public getData(): ApiNews[] {
    return this.data;
  }
}

export class ApiGetSingeNewsResponse {
  constructor(public data) {
  }

  public getData(): ApiNews {
    return this.data;
  }
}

export class ApiNews {
  constructor(public id: number,
              public title: string,
              public summary: string,
              public content: string,
              public publishedOn: string,
              public validFrom: string,
              public validUntil: string,
              public isActive: boolean,
              public sortOrder: number) {
  }
}
