import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {EditCategory} from './edit-category.model';
import {ProductService} from './../../api/product.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {CategoryService} from 'app/views/products/categories/category.service';
import {ListProductCategory} from 'app/views/products/product-list/list-product-category.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-edit-category-product',
    templateUrl: './edit-category.component.html',
    styleUrls: ['./edit-category.component.scss']
})
export class EditCategoryComponent {

    categoryForm: FormGroup;
    category: EditCategory;
    categoryId: number;
    orderedCategories: ListProductCategory[];
    
    constructor(private fb: FormBuilder, private productService: ProductService, private categoryService: CategoryService,
        private route: ActivatedRoute, private router: Router, private toastr: ToastrService,
        private spinnerService: NgxSpinnerService) {
        this.createForm();
    }

    ngOnInit() {
        this.spinnerService.show();

        this.getInitialData();
    }

    getInitialData() {
        this.route.params.subscribe(params => {
            this.categoryId = parseInt(params['id']);
            this.productService.getCategory(this.categoryId).subscribe(
                category => {
                    this.category = category;
                    this.categoryService.getOrderedListProductCategories().subscribe(
                        categories => {
                            this.orderedCategories = categories;
                            this.setFormValues();
                            this.spinnerService.hide();
                        });
                });
        });
    }

    createForm() {
        this.categoryForm = this.fb.group({
            name: ['', Validators.required],
            meta_title: '',
            meta_keywords: '',
            meta_description: '',
            description: '',
            parentCategoryId: '',
            isActive: ''
        });
    }

    setFormValues() {
        this.categoryForm.setValue({
            name: this.category.name,
            meta_title: this.category.meta_title,
            meta_keywords: this.category.meta_keywords,
            meta_description:  this.category.meta_description,
            description: this.category.description,
            parentCategoryId: this.category.parentCategoryId,
            isActive: this.category.isActive
        });
    }

    onSubmit() {
        var editCategory = this.prepareEditCategory();
        this.productService.updateCategory(this.categoryId, editCategory)
            .subscribe(
            response => {
            },
            error => {
            },
            () => {
                this.toastr.success('Category updated successfully');
                this.router.navigate(['./products/categories/list']);
            });
    }

    prepareEditCategory(): EditCategory {
        const formModel = this.categoryForm.value;

        const editCategory: EditCategory = {
            name: formModel.name as string,
            meta_title: formModel.meta_title as string,
            meta_keywords: formModel.meta_keywords as string,
            meta_description: formModel.meta_description as string,
            description: formModel.description as string,
            isActive: formModel.isActive as boolean,
            parentCategoryId: formModel.parentCategoryId as number,
        };
        return editCategory;
    }

    isValidForm() {
        return this.categoryForm.status == "VALID";
    }

    get name() { return this.categoryForm.get('name'); }

    get meta_title() { return this.categoryForm.get('meta_title'); }
    get meta_keywords() { return this.categoryForm.get('meta_keywords'); }
    get meta_description() { return this.categoryForm.get('meta_description'); }
}