import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Observable} from 'rxjs/Observable';
import {UsersService} from '../users.service';
import {User} from '../../../user/user.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html'
})
export class UsersListComponent implements OnInit {

  displayedColumns = ['id', 'name', 'email', 'isAdmin', 'notificationsEnabled', 'actions'];
  dataSource: UserDatasource | null;
  database: UserDatabase;
  modalRef: BsModalRef;
  userId: number;

  @ViewChild('filter', {static: true}) public filter: ElementRef;
  @ViewChild(MatSort,{static: true}) public sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) public paginator: MatPaginator;

  constructor(private toastr: ToastrService, private router: Router, private modalService: BsModalService,
              private usersService: UsersService) {

  }

  ngOnInit(): void {
    this.database = new UserDatabase(this.usersService);
    this.dataSource = new UserDatasource(this.database, this.paginator, this.sort)

    Observable.fromEvent(this.filter.nativeElement, 'keyup')
      .debounceTime(150)
      .distinctUntilChanged()
      .subscribe(() => {
        if (!this.dataSource) {
          return;
        }
        this.dataSource.filter = this.filter.nativeElement.value;
      });
  }

  redirectToEdit(userId) {
    this.router.navigate(['./users/edit/' + userId]);
  }

  openModal(template: TemplateRef<any>, userId: number) {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
    this.userId = userId;
  }

  confirm(): void {
    this.usersService.deleteUser(this.userId)
      .subscribe(
        () => {
          this.toastr.success('User removed successfully', '');
          this.deleteRowDataTable(this.userId);
        });
    this.modalRef.hide();
  }

  decline(): void {
    this.userId = null;
    this.modalRef.hide();
  }

  private deleteRowDataTable(itemId) {
    const dsData = this.database.data;
    const itemIndex = dsData.findIndex(obj => obj['id'] === itemId);
    dsData.splice(itemIndex, 1)
    this.database.dataChange.next(dsData);
  }
}

export class UserDatabase {
  /** Stream that emits whenever the data has been modified. */
  dataChange: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);

  get data(): User[] {
    return this.dataChange.value;
  }

  constructor(private usersService: UsersService) {
    this.usersService.getUsers().subscribe(
      user => {
        this.dataChange.next(user);
      });
  }
}


export class UserDatasource extends DataSource<User> {
  _filterChange = new BehaviorSubject('');

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  prevFilterValue: string;

  constructor(private _database: UserDatabase, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this.prevFilterValue = this.filter.toLowerCase();
  }

  connect(collectionViewer: CollectionViewer): Observable<User[]> {
    const displayDataChanges = [
      this._database.dataChange,
      this._sort.sortChange,
      this._filterChange,
      this._paginator.page
    ];
    return Observable.merge(...displayDataChanges).map(() => {

      const data = this._database.data.slice().filter((item: User) => {
        if (item.name == null && item.email == null) {
          return false;
        }

        return item.name.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1
               || item.email.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1;
      });

      if (this.filter.toLowerCase() !== this.prevFilterValue) {
        this._paginator.pageIndex = 0;
      }
      this.prevFilterValue = this.filter.toLowerCase();

      // Sort filtered data
      const sortedData = this.sortData(data.slice());

      // Grab the user's slice of data.
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return sortedData.splice(startIndex, this._paginator.pageSize);
    });
  }

  disconnect(collectionViewer: CollectionViewer): void {
  }
  /** Returns a sorted copy of the database data. */
  sortData(data: User[]): User[] {
    if (!this._sort.active || this._sort.direction === '') { return data; }

    return data.sort((a, b) => {
      let propertyA: number|string = '';
      let propertyB: number|string = '';

      switch (this._sort.active) {
        case 'id': [propertyA, propertyB] = [a.id, b.id]; break;
        case 'name': [propertyA, propertyB] = [a.name, b.name]; break;
        case 'email': [propertyA, propertyB] = [a.email, b.email]; break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }

}
