export class News {
  constructor(public id: number,
              public title: string,
              public summary: string,
              public content: string,
              public publishedOn: string,
              public validFrom: string,
              public validUntil: string,
              public isActive: boolean) {
  }
}
