import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {AddCategory} from './add-category.model';
import {ProductService} from './../../api/product.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {CategoryService} from 'app/views/products/categories/category.service';
import {ListProductCategory} from 'app/views/products/product-list/list-product-category.model';
import {UserService} from 'app/user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-add-product-category',
    templateUrl: './add-category.component.html',
    styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit {

    categoryForm: FormGroup;
    orderedCategories: ListProductCategory[];

    constructor(private fb: FormBuilder, private productService: ProductService, private categoryService: CategoryService,
        private router: Router, private toastr: ToastrService,
        private spinnerService: NgxSpinnerService, private userService: UserService) {
        this.createForm();
        this.getIntialData();
    }

    ngOnInit() {
        this.spinnerService.show();
        this.getIntialData();
    }

    getIntialData() {
        // this.categoryService.getOrderedListProductCategories().subscribe(
        //     categories => {
        //         this.orderedCategories = categories;
        //         this.spinnerService.hide();
        //     });
        this.categoryService.getFilteredOrderedListProductCategories().subscribe(
            categories => {
                this.orderedCategories = categories;
                this.spinnerService.hide();
            });
    }

    onSubmit() {
        var addCategory = this.prepareAddCategory();
        this.productService.addCategory(addCategory)
            .subscribe(
            response => {
            },
            error => {
            },
            () => {
                this.toastr.success('Category created successfully');
                this.router.navigate(['./products/categories/list']);
            });
    }

    onReset() {
        this.categoryForm.reset();
    }

    prepareAddCategory(): AddCategory {
        const formModel = this.categoryForm.value;

        const addCategory: AddCategory = {
            name: formModel.name as string,
            description: formModel.description as string,
            isActive: formModel.isActive as boolean,
            parentCategoryId: formModel.parentCategoryId as number,
            templateId: 0
        };
        return addCategory;
    }

    createForm() {
        this.categoryForm = this.fb.group({
            name: ['', Validators.required],
            description: '',
            parentCategoryId: '',
            isActive: false
        });
    }

    isValidForm() {
        return this.categoryForm.status == "VALID";
    }

    get name() {
        return this.categoryForm.get('name');
    }

}

