import { LumenOutputRangeItems } from './LumenOutputRangeItems.model';

export class LumenOutputRange {
  constructor(
    public id: number,
    public name: string,
    public strip: number,
    public items: LumenOutputRangeItems[],
  ) {
  }
}