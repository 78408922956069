<div class="sidebar">
  <app-sidebar-header></app-sidebar-header>
  <nav class="sidebar-nav">
    <ul class="nav">
      <li class="nav-title">
        Menu
      </li>
      <li class="divider"></li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/dashboard']">
          <i class="icon-speedometer"></i> Dashboard
        </a>
      </li>
      <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
          <i class="fa fa-newspaper-o"></i> Blog</a>
        <a [routerLink]="['/blog']" hidden></a>
        <ul class="nav-dropdown-items">
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="icon-notebook"></i> Main categories</a>
            <a [routerLink]="['/blog/pages']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/blog/main-categories/list']">
                  <i class="icon-list"></i> List</a>
              </li>
            </ul>
          </li>
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="icon-tag"></i> Blog Tags</a>
            <a [routerLink]="['/blog/tags']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/blog/tags/add']">
                  <i class="icon-plus"></i> Add</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/blog/tags/list']">
                  <i class="icon-list"></i> List</a>
              </li>
            </ul>
          </li>
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="fa fa-newspaper-o"></i> Articles</a>
            <a [routerLink]="['/blog/articles']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/blog/articles/add']">
                  <i class="icon-plus"></i> Add</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/blog/articles/list']">
                  <i class="icon-list"></i> List</a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
          <i class="fa fa-pencil-square-o"></i> Content</a>
        <a [routerLink]="['/content']" hidden></a>
        <ul class="nav-dropdown-items">
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="fa fa-newspaper-o"></i> News</a>
            <a [routerLink]="['/content/news']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/content/news/list']">
                  <i class="fa fa-newspaper-o"></i> List</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/content/news/add']">
                  <i class="fa fa-newspaper-o"></i> Add</a>
              </li>
            </ul>
          </li>
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="fa fa-file-code-o"></i> Pages</a>
            <a [routerLink]="['/content/pages']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/content/pages/list']">
                  <i class="fa fa-file-code-o"></i> List</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/content/pages/add']">
                  <i class="fa fa-file-code-o"></i> Add</a>
              </li>
            </ul>
          </li>
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="fa fa-camera"></i> Home slider</a>
              <a [routerLink]="['/content/pages']" hidden></a>
              <ul class="nav-dropdown-items">
                <li class="nav-item">
                  <a class="nav-link" routerLinkActive="active" [routerLink]="['/content/slider/list']">
                    <i class="fa fa-file-code-o"></i> List</a>
                </li>
                <li class="nav-item" *ngIf="!isRestricted()">
                  <a class="nav-link" routerLinkActive="active" [routerLink]="['/content/slider/add']">
                    <i class="fa fa-file-code-o"></i> Add</a>
                </li>
              </ul>
          </li>
          <li class="nav-item ">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/content/applicationGroups/list']">
              <i class="fa fa-photo"></i> Applications</a>
          </li>
          <li class="nav-item ">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/content/files/']">
              <i class="fa fa-file"></i> Files</a>
          </li>
        </ul>
      </li>

      <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
          <i class="icon-cloud-download"></i> Download</a>
        <a [routerLink]="['/download']" hidden></a>
        <ul class="nav-dropdown-items">
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="icon-tag"></i> Download Tags</a>
            <a [routerLink]="['/download-tag']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/download/download-tag/list']">
                  <i class="icon-list"></i> List</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/download/download-tag/add']">
                  <i class="icon-plus"></i> Add</a>
              </li>
            </ul>
          </li>
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="icon-cloud-download"></i> Downloads</a>
            <a [routerLink]="['/download']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/download/list']">
                  <i class="icon-list"></i> List</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/download/add']">
                  <i class="icon-plus"></i> Add</a>
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <li class="nav-divider "></li>
      <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
          <i class="icon-puzzle"></i> Products</a>
        <a [routerLink]="['/products']" hidden></a>
        <ul class="nav-dropdown-items">
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/products/list']">
              <i class="icon-puzzle"></i> List</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/products/add']">
              <i class="icon-puzzle"></i> Add</a>
          </li>
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="icon-puzzle"></i> Categories</a>
            <a [routerLink]="['/products/categories']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/products/categories/list']">
                  <i class="icon-puzzle"></i> List</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/products/categories/add']">
                  <i class="icon-puzzle"></i> Add</a>
              </li>
            </ul>
          </li>
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="icon-puzzle"></i> Features</a>
            <a [routerLink]="['/products/features']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/products/features/list']">
                  <i class="icon-puzzle"></i> List</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/products/features/add']">
                  <i class="icon-puzzle"></i> Add</a>
              </li>
            </ul>
          </li>
        </ul>

      </li>
      <li class="nav-divider "></li>
      <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
          <i class="fa fa-building"></i> Distributors</a>
        <a [routerLink]="['/distributors']" hidden></a>
        <ul class="nav-dropdown-items">
          <!-- <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/list']">
              <i class="fa fa-building"></i>Old List</a>
          </li> -->
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="icon-tag"></i> Categories</a>
            <a [routerLink]="['/distributors/categories/list']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/categories/add']">
                  <i class="icon-plus"></i> Add</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/categories/list']">
                  <i class="icon-list"></i> List</a>
              </li>
            </ul>
          </li>
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="icon-tag"></i> Subcategories</a>
            <a [routerLink]="['/distributors/sub-categories/list']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/sub-categories/add']">
                  <i class="icon-plus"></i> Add</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/sub-categories/list']">
                  <i class="icon-list"></i> List</a>
              </li>
            </ul>
          </li>
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="icon-tag"></i> Posts</a>
            <a [routerLink]="['/distributors/post/list']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/post/add']">
                  <i class="icon-plus"></i> Add</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/post/list']">
                  <i class="icon-list"></i> List</a>
              </li>
            </ul>
          </li>

          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
            <i class="fa fa-newspaper-o"></i> News</a>

            <a [routerLink]="['/distributors/news/list']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/news/add']">
                  <i class="icon-plus"></i> Add</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/news/list']">
                  <i class="icon-list"></i> List</a>
              </li>
            </ul>
          </li>
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="fa fa-users"></i> Users</a>
            <a [routerLink]="['/distributors/user/list']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/user/add']">
                  <i class="icon-plus"></i> Add</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/user/list']">
                  <i class="icon-list"></i> List</a>
              </li>
            </ul>
          </li>
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="icon-picture"></i> Slider</a>
            <a [routerLink]="['/distributors/slider/list']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/slider/list']">
                  <i class="icon-list"></i> List</a>
              </li>
            </ul>
          </li>
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="fa fa-users"></i> Team</a>
            <a [routerLink]="['/distributors/helpers/list']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/helper/add']">
                  <i class="icon-plus"></i> Add</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/helper/list']">
                  <i class="icon-list"></i> List</a>
              </li>
            </ul>
          </li>
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="fa fa-globe"></i> Countries</a>
            <a [routerLink]="['/distributors/countries']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/countries/list']">
                  <i class="fa fa-globe"></i> List</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/countries/add']">
                  <i class="fa fa-globe"></i> Add</a>
              </li>
            </ul>
          </li>
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="fa fa-flag-o"></i> Regions</a>
            <a [routerLink]="['/distributors/region/list']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/region/add']">
                  <i class="icon-plus"></i> Add</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/region/list']">
                  <i class="icon-list"></i> List</a>
              </li>
            </ul>
          </li>
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="fa fa-adjust"></i> Roles</a>
            <a [routerLink]="['/distributors/roles/list']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/roles/add']">
                  <i class="icon-plus"></i> Add</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/distributors/roles/list']">
                  <i class="icon-list"></i> List</a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="nav-divider "></li>
      <li class="nav-item nav-dropdown" routerLinkActive="open" *ngIf="(user | async)?.isAdmin" appNavDropdown>
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
          <i class="fa fa-users"></i> Users</a>
        <a [routerLink]="['/users']" hidden></a>
        <ul class="nav-dropdown-items">
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/users/list']">
              <i class="fa fa-building"></i> List</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/users/add']">
              <i class="fa fa-building"></i> Add</a>
          </li>
        </ul>
      </li>
      <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
          <i class="fa fa-building"></i> Configurator</a>
        <a [routerLink]="['/configurators']" hidden></a>
        <ul class="nav-dropdown-items">
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/configurators/list']">
              <i class="fa fa-building"></i> List</a>
          </li>
           <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/lumen-output-range/list']">
              <i class="fa fa-table"></i>Lumen Output Range</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/dependencies/list']">
              <i class="fa fa-retweet"></i>Dependencies</a>
          </li>
        </ul>
      </li>
      <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
          <i class="icon-grid"></i> Pimcore</a>
        <a [routerLink]="['/pimcore']" hidden></a>
        <ul class="nav-dropdown-items">
          <li class="nav-item">
          <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
            <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
              <i class="icon-refresh"></i> Synchronization</a>
            <a [routerLink]="['/pimcore/synchronization']" hidden></a>
            <ul class="nav-dropdown-items">
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/pimcore/synchronization/add']">
                  <i class="icon-plus"></i> Add</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/pimcore/synchronization/list']">
                  <i class="icon-list"></i> List</a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown >
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
          <i class="fa fa-pencil-square-o"></i> Projects</a>
        <a [routerLink]="['/projects']" hidden></a>
        <ul class="nav-dropdown-items">
          <li class="nav-item">
            <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
              <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
                <i class="icon-home"></i> Building Types</a>
              <a [routerLink]="['/projects/building-types']" hidden></a>
              <ul class="nav-dropdown-items">
                <li class="nav-item">
                  <a class="nav-link" routerLinkActive="active" [routerLink]="['/projects/building-types/add']">
                    <i class="icon-plus"></i> Add</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLinkActive="active" [routerLink]="['/projects/building-types/list']">
                    <i class="icon-list"></i> List</a>
                </li>
              </ul>
            </li>
            <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
              <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
                <i class="icon-globe"></i> Spaces</a>
              <a [routerLink]="['/projects/spaces']" hidden></a>
              <ul class="nav-dropdown-items">
                <li class="nav-item">
                  <a class="nav-link" routerLinkActive="active" [routerLink]="['/projects/spaces/add']">
                    <i class="icon-plus"></i> Add</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLinkActive="active" [routerLink]="['/projects/spaces/list']">
                    <i class="icon-list"></i> List</a>
                </li>
              </ul>
            </li>

          <li class="nav-item" >
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/projects/item/add']">
              <i class="fa fa-plus"></i> Add Project</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/projects/item/list']">
              <i class="icon-list"></i> List</a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
  <app-sidebar-footer></app-sidebar-footer>
</div>
