import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LumenOutputRangeListComponent} from './lumen-output-range-list/lumen-output-range-list.component';
import {EditLumenOutputRangeComponent} from './lumen-output-range-edit/edit-lumen-output-range.component';


const routes: Routes = [
  {
    path: '',
    data: {
      title: 'LumenOutputRange'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list'
      },
      {
        path: 'list',
        component: LumenOutputRangeListComponent,
        data: {
          title: 'LumenOutputRangeList'
        }
      },
      {
        path: 'edit/:id',
        component: EditLumenOutputRangeComponent,
        data: {
          title: 'EditLumenOutputRange'
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class LumenOutputRangeRoutingModule { }
