export class EditCategory {
  constructor(
    public name: string,
    public meta_title: string,
    public meta_keywords: string,
    public meta_description: string,
    public description: string,
    public isActive: boolean,
    public parentCategoryId: number) {
  }
}