import { RolesAddComponent } from './roles-add/roles-add.component';
import { RolesEditComponent } from './roles-edit/roles-edit.component';
import { RolesListComponent } from './roles-list/roles-list.component';
import { RegionsAddComponent } from './regions-add/regions-add.component';
import { RegionsEditComponent } from './regions-edit/regions-edit.component';
import { RegionsListComponent } from './regions-list/regions-list.component';
import { HelpersAddComponent } from './helpers-add/helpers-add.component';
import { HelpersEditComponent } from './helpers-edit/helpers-edit.component';
import { HelpersListComponent } from './helpers-list/helpers-list.component';
import { SliderEditComponent } from './slider-edit/slider-edit.component';
import { SliderListComponent } from './slider-list/slider-list.component';
import { NewsEditComponent } from './news-edit/news-edit.component';
import { NewsAddComponent } from './news-add/news-add.component';
import { NewsListComponent } from './news-list/news-list.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserListComponent } from './user-list/user-list.component';
import { PostEditComponent } from './post-edit/post-edit.component';
import { PostAddComponent } from './post-add/post-add.component';
import { PostListComponent } from './post-list/post-list.component';
import { CategoryEditComponent } from './category-edit/category-edit.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { CategoryAddComponent } from './category-add/category-add.component';
import { SubCategoryListComponent } from './sub-category-list/sub-category-list.component';
import { SubCategoryAddComponent } from './sub-category-add/sub-category-add.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AddDistributorComponent} from './distributor-add/add-distributor.component';
import {DistributorsListComponent} from './distributors-list/distributors-list.component';
import {EditDistributorComponent} from './distributor-edit/edit-distributor.component';
import {CountriesListComponent} from './country/country-list/countries-list.component';
import {AddCountryComponent} from './country/country-add/add-country.component';
import {EditCountryComponent} from './country/country-edit/edit-country.component';
import {CanActivateViaStandardUserGuard} from '../../can-activate-via-standard-guard';
import { SubCategoryEditComponent } from './sub-category-edit/sub-category-edit.component';
import { UserAddComponent } from './user-add/user-add.component';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Distributors'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list'
      },
      {
        path: 'categories/list',
        component: CategoryListComponent,
        data: {
          title: 'Categories list'
        }
      },
      {
        path: 'categories/add',
        component: CategoryAddComponent,
        data: {
          title: 'Add category'
        }
      },
      {
        path: 'categories/edit/:id',
        component: CategoryEditComponent,
        data: {
          title: 'Edit category'
        }
      },
      {
        path: 'sub-categories/list',
        component: SubCategoryListComponent,
        data: {
          title: 'Subcategories list'
        }
      },
      {
        path: 'sub-categories/add',
        component: SubCategoryAddComponent,
        data: {
          title: 'Add subcategory'
        }
      },
      {
        path: 'sub-categories/edit/:id',
        component: SubCategoryEditComponent,
        data: {
          title: 'Edit category'
        }
      },
      {
        path: 'post/list',
        component: PostListComponent,
        data: {
          title: 'Posts list'
        }
      },
      {
        path: 'slider/list',
        component: SliderListComponent,
        data: {
          title: 'Slider list'
        }
      },
      {
        path: 'slider/edit/:id',
        component: SliderEditComponent,
        data: {
          title: 'Slider edit'
        }
      },
      {
        path: 'post/add',
        component: PostAddComponent,
        data: {
          title: 'Add post'
        }
      },
      {
        path: 'post/edit/:id',
        component: PostEditComponent,
        data: {
          title: 'Edit post'
        }
      },
      {
        path: 'user/list',
        component: UserListComponent,
        data: {
          title: 'Users list'
        }
      },
      {
        path: 'user/edit/:id',
        component: UserEditComponent,
        data: {
          title: 'Users edit'
        }
      },
      {
        path: 'user/add',
        component: UserAddComponent,
        data: {
          title: 'User add'
        }
      },
      {
        path: 'news/list',
        component: NewsListComponent,
        data: {
          title: 'News list'
        }
      },
      {
        path: 'news/edit/:id',
        component: NewsEditComponent,
        data: {
          title: 'News edit'
        }
      },
      {
        path: 'news/add',
        component: NewsAddComponent,
        data: {
          title: 'News add'
        }
      },
      {
        path: 'list',
        component: DistributorsListComponent,
        data: {
          title: 'DistributorList'
        }
      },
      {
        path: 'add',
        component: AddDistributorComponent,
        data: {
          title: 'AddDistributor'
        }
      },
      {
        path: 'edit/:id',
        component: EditDistributorComponent,
        data: {
          title: 'EditDistributor'
        }
      },
      {
        path: 'countries',
        data: {
          title: 'Countries'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'list'
          },
          {
            path: 'list',
            component: CountriesListComponent,
            data: {
              title: 'Country list'
            }
          },
          {
            path: 'add',
            component: AddCountryComponent,
            data: {
              title: 'Add country'
            },
            // canActivate: [CanActivateViaStandardUserGuard],
          },
          {
            path: 'edit/:id',
            component: EditCountryComponent,
            data: {
              title: 'Edit country'
            }
          }
        ]
      },

      {
        path: 'helper/list',
        component: HelpersListComponent,
        data: {
          title: 'Team user list'
        }
      },
      {
        path: 'helper/edit/:id',
        component: HelpersEditComponent,
        data: {
          title: 'Team user edit'
        }
      },
      {
        path: 'helper/add',
        component: HelpersAddComponent,
        data: {
          title: 'Team user add'
        }
      },
      {
        path: 'region/list',
        component: RegionsListComponent,
        data: {
          title: 'Region list'
        }
      },
      {
        path: 'region/edit/:id',
        component: RegionsEditComponent,
        data: {
          title: 'Region edit'
        }
      },
      {
        path: 'region/add',
        component: RegionsAddComponent,
        data: {
          title: 'Region add'
        }
      },
      {
        path: 'roles/list',
        component: RolesListComponent,
        data: {
          title: 'Roles list'
        }
      },
      {
        path: 'roles/edit/:id',
        component: RolesEditComponent,
        data: {
          title: 'Role edit'
        }
      },
      {
        path: 'roles/add',
        component: RolesAddComponent,
        data: {
          title: 'Role add'
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DistributorsRoutingModule {}
