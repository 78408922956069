<div class="animated fadeIn">
  <div class="example-container">
    <div class="example-header">
      <mat-form-field floatPlaceholder="never">
        <input matInput #filter placeholder="Filter">
      </mat-form-field>
      <div style="width:5%"></div>
      <div style="width:30%">
        <select class="form-control" (change)="onTypeChange($event.target.value)">
          <option value="-1">Select category</option>
          <option value="0">Distributor</option>
          <option value="1">Representative</option>
          <option value="2">Subpartner</option>
          <option value="3">DIY</option>
          <option value="4">A/V Channel Reps</option>
          <option value="5">On-Line Partners</option>
          <option value="6">International Reps</option>
        </select>
      </div>
    </div>

    <mat-paginator #paginator [length]="database.data.length" [pageIndex]="0" [pageSize]="50" [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

    <mat-table #table [dataSource]="dataSource" matSort>

      <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Id </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.id}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef  mat-sort-header> Name </mat-header-cell>
        <mat-cell *matCellDef="let row"> <span [innerHTML]="row.name"></span> </mat-cell>
      </ng-container>

      <ng-container matColumnDef="address">
        <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
        <mat-cell *matCellDef="let row"> <span [innerHTML]="row.address"></span> </mat-cell>
      </ng-container>
      <ng-container matColumnDef="city">
        <mat-header-cell *matHeaderCellDef mat-sort-header> City </mat-header-cell>
        <mat-cell *matCellDef="let row"> <span [innerHTML]="row.city"></span> </mat-cell>
      </ng-container>

      <ng-container matColumnDef="country">
        <mat-header-cell *matHeaderCellDef> Country </mat-header-cell>
        <mat-cell *matCellDef="let row"> <span [innerHTML]="row.country.name"></span> </mat-cell>
      </ng-container>
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span *ngIf="row.type == 0" >Distributor</span>
          <span *ngIf="row.type == 1" >Representative</span>
          <span *ngIf="row.type == 2" >Subpartner</span>
          <span *ngIf="row.type == 3" >DIY</span>
          <span *ngIf="row.type == 4" >A/V Channel Reps</span>
          <span *ngIf="row.type == 5" >On-Line Partners</span>
          <span *ngIf="row.type == 6" >International Reps</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="active">
        <mat-header-cell *matHeaderCellDef> Is active </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span *ngIf="row && row.isActive == 1" class="badge badge-success">Active</span>
          <span *ngIf="row && row.isActive == 0" class="badge badge-dark">Inactive</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button type="button" class="btn btn-primary mr-1" (click)="redirectToEdit(row.id)">
            Edit
          </button>
          <button type="button" class="btn btn-danger" (click)="openModal(template, row.id)">
            Delete
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

  </div>
</div>

<ng-template #template>
  <div class="modal-body text-center">
    <p>Do you want to confirm?</p>
    <button type="button" class="btn btn-default" (click)="confirm()">Yes</button>
    <button type="button" class="btn btn-primary" (click)="decline()">No</button>
  </div>
</ng-template>
