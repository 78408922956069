import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from 'environments/environment';
import 'rxjs/Rx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorService } from '../../error.service';
import { User } from '../../user/user.model';
import { UserService } from '../../user/user.service';
import { LumenOutputRange } from './LumenOutputRange.model';
import { LumenOutputRangeItems } from './LumenOutputRangeItems.model';
import { ApiLumenOutputRange, ApiGetLumenOutputRangeResponse } from './api/api-lumen-output-range-responses.model';



@Injectable()
export class LumenOutputRangeService {

  private headers: HttpHeaders;
  private apiUrl: string; 
  private user: User;

  constructor(private http: HttpClient, private userService: UserService, private errorService: ErrorService) {
    this.apiUrl = environment.apiUrl;
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.user = this.userService.getCachedUser();
    this.userService.dataChange.subscribe((user) => {
      if (user != null) {
        this.user = user;
      }
    });
  }

  getLumenOutputRange(): Observable<LumenOutputRange[]> {

    return Observable.forkJoin([
      this.http.get(this.apiUrl + '/lumenOutputRangeApi?langId=' + this.user.languageId)
    ])
      .map((data: any[]) => {
        const lumenOutputResponse: ApiGetLumenOutputRangeResponse = data[0];
        const listLumenOutput = [];
        for (const lumenOutput of lumenOutputResponse.data) {
          const newListLumenOutput = new LumenOutputRange(
            lumenOutput.id, lumenOutput.name, lumenOutput.strip, lumenOutput.items
          );
          listLumenOutput.push(newListLumenOutput);
        }
        return listLumenOutput;
      })
      .catch(this.errorService.handleError<any>('getLumenOutputRange')); 
  }

  getSingleLumenOutputRange(lumenOutputId: number): Observable<LumenOutputRange> {
    return this.http.get(this.apiUrl + '/lumenOutputRangeApi/' + lumenOutputId + '?langId=' + this.user.languageId)

      .map((response: ApiGetLumenOutputRangeResponse) => {
        const apiLumenOutputRange: ApiLumenOutputRange = response.data;

        const items: LumenOutputRangeItems[] = apiLumenOutputRange.items.data.sort((item1, item2) => {
          return item1.sortOrder - item2.sortOrder
        })

        const lumenOutput: LumenOutputRange = {
          id: apiLumenOutputRange.id,
          name: apiLumenOutputRange.name,
          strip: apiLumenOutputRange.strip,
          items: items,

        }
        return lumenOutput;
      })

      .catch(this.errorService.handleError<any>('getSingleLumenOutputRange'));
  } 
  

  updateLumenOutputRangeItemsIsActive(isActive: boolean, lumenOutputRangeId: number): Observable<LumenOutputRangeItems[]> {
    return this.http
      .post(this.apiUrl + '/lumenOutputRangeApi/' + lumenOutputRangeId + '/updateLumenOutputRangeItemsIsActive' + '?langId=' + this.user.languageId,  {isActive : isActive, lumenOutputRangeId: lumenOutputRangeId}, { headers: this.headers })
      .catch(this.errorService.handleError<any>('updateLumenOutputRangeItemsIsActive'));
  }

   updateLumenOutputRange(lumenOutputRangeId: number, lumenOutputRange: LumenOutputRange) {
    return this.http
      .put(this.apiUrl + '/lumenOutputRangeApi/' + lumenOutputRangeId + '?langId=' + this.user.languageId, JSON.stringify(lumenOutputRange), { headers: this.headers })
      .catch(this.errorService.handleError<any>('updateLumenOutputRange'));
  }

  updateLumenOutputRangeItems(lumenOutputRangeId: number, itemName: string, lumenOutputRangeMinIntValue: number, lumenOutputRangeMaxIntValue: number): Observable<LumenOutputRangeItems[]> {
    return this.http
      .put(this.apiUrl + '/lumenOutputRangeApi/LumenOutputRangeItems' + '?langId='
        + this.user.languageId, JSON.stringify({ name: itemName, lumenOutputRangeId: lumenOutputRangeId, minIntValue: lumenOutputRangeMinIntValue, maxIntValue: lumenOutputRangeMaxIntValue }), { headers: this.headers })
      .catch(this.errorService.handleError<any>('updateLumenOutputRangeItems'));
  }
 
  lumenOutputRangeItemsStortUp(lumenOutputRangeId: number) {
    return this.http.post(this.apiUrl + '/lumenOutputRangeApi/' + lumenOutputRangeId + '/sortUp', {}, { headers: this.headers })
      .map(() => { })
      .catch(this.errorService.handleError<any>('lumenOutputRangeItemsStortUp'));
  }
  lumenOutputRangeItemsStortDown(lumenOutputRangeId: number) {
    return this.http.post(this.apiUrl + '/lumenOutputRangeApi/' + lumenOutputRangeId + '/sortDown', {}, { headers: this.headers })
      .map(() => { })
      .catch(this.errorService.handleError<any>('lumenOutputRangeItemsStortDown'));
  }

  addLumenOutputRangeItems(lumenOutputRangeId: number, itemName: string, itemMinValue: number, itemMaxValue: number): Observable<LumenOutputRangeItems[]> {
    const data: any = { lumenOutputRangeId: lumenOutputRangeId }
    if (itemName) {
      data.name = itemName;
    }
    if (itemMinValue) {
      data.minIntValue = itemMinValue;
    }
    if (itemMaxValue) {
      data.maxIntValue = itemMaxValue;
    }

    return this.http
      .post(this.apiUrl + '/lumenOutputRangeApi/StoreLumenOutputRangeItems?langId=' + this.user.languageId, data, { headers: this.headers })
      .map((response: ApiGetLumenOutputRangeResponse) => {
        const apiLumenOutputRange: ApiLumenOutputRange = response.data;
        return apiLumenOutputRange.items.data;
      })
      .catch(this.errorService.handleError<any>('addLumenOutputRangeItems'));
  }

  deleteLumenOutputRangeItems(lumenOutputRangeId: number) {
    return this.http
      .delete(this.apiUrl + '/lumenOutputRangeApi/DeleteLumenOutputRangeItems/' + lumenOutputRangeId + '?langId=' + this.user.languageId, { headers: this.headers })
      .catch(this.errorService.handleError<any>('deleteLumenOutputRangeItems'));
  }
}
