import { CategoryList } from "../Distributor.model";

export class ApiGetDistributorsResponse {
  constructor(public data) {
  }
  public getData(): ApiDistributor[] {
    return this.data;
  }
}

export class ApiGetDistributorResponse {
  constructor(public data) {
  }
  public getData(): ApiDistributor {
    return this.data;
  }
}

export class ApiGetDistributorUserResponse {
  constructor(public data) {
  }
  public getData(): ApiDistributorUser {
    return this.data;
  }
}

export class ApiDistributor {
  constructor(public id: number,
              public name: string,
              public address: string,
              public postalCode: string,
              public city: string,
              public country_id: number,
              public voivodeship_id: number,
              public tel: string,
              public tel2: string,
              public email: string,
              public www: string,
              public type: number,
              public latitude: string,
              public longitude: string,
              public company: string,
              public isActive: boolean,
              public isVerified?:boolean,
              public is_visible_www?:boolean
              ) {
  }
}

export class ApiDistributorUser {
  constructor(public id: number,
              public name: string,
              public address: string,
              public postalCode: string,
              public city: string,
              public country_id: number,
              public voivodeship_id: number,
              public tel: string,
              public tel2: string,
              public email: string,
              public www: string,
              public type: number,
              public latitude: string,
              public longitude: string,
              public isActive: boolean,
              public isVerified?:boolean
              ) {
  }
}
export class ApiGetCategoryListResponse {
  constructor(public data) {
  }
  public getData(): CategoryList {
      return this.data;
  }
}
