export class ApplicationGroup {
  constructor(
    public id: number,
    public title: string,
    public content: string,
    public slug: string,
    public applications: Application[]
  ) {}
}


export class Application {
  constructor(
    public id: number,
    public title: string,
    public slug: string,
    public content: string,
    public galleryId: number,
    public applicationGroupId: number,
    public sortOrder: number
  ) {}
}
