/**
 * Created by hawlik on 08.02.18.
 */
import { Country } from './country/country.model';

export class Distributor {
  constructor(public id: number,
    public name: string,
    public address: string,
    public postalCode: string,
    public city: string,
    public country_id: number,
    public country: Country,
    public voivodeship_id: number,
    public tel: string,
    public tel2: string,
    public email: string,
    public www: string,
    public type: number,
    public latitude: string,
    public longitude: string,
    public company: string,
    public isActive: boolean,
    public is_visible_www?: boolean) {
  }
}

export class TraderHelper {
  constructor(public id: number,
    public name: string,
    public surname: string,
    public email: string,
    public phone: string,
    public main_image_src: string,
    public is_active: boolean) {
  }
}
export class DistributorUser {
  constructor(public id: number,
    public company: string,
    public name: string,
    public address: string,
    public postalCode: string,
    public city: string,
    public country_id: number,
    public country: Country,
    public voivodeship_id: number,
    public tel: string,
    public tel2: string,
    public email: string,
    public www: string,
    public type: number,
    public latitude: string,
    public longitude: string,
    public isActive: boolean,
    public isVerified: boolean,
    public main_image_src: string,
    public is_visible_www?: boolean
    ) {
  }
}

export class CategoryList {
  constructor(
    public id: number,
    public order: number,
    public created_at: string,
    public updated_at: string,
    public title?: string,
    public parent?: CategoryEdit,
    public parent_id?: number
  ) { }
}

export class RegionList {
  constructor(
    public id: number,
    public created_at: string,
    public updated_at: string,
    public title?: string,
  ) { }
}
export class RoleList {
  constructor(
    public id: number,
    public created_at: string,
    public updated_at: string,
    public title?: string,
  ) { }
}

export class CategoryAdd {
  constructor(
    public id?: number,
    public title?: string,
    public description?: string,
  ) { }
}
export class CategoryEdit {
  constructor(
    public title: string,
    public description: string,
  ) { }
}
export class RegionEdit {
  constructor(
    public title: string,
  ) { }
}
export class RoleEdit {
  constructor(
    public title: string,
  ) { }
}

export class SubCategoryAdd {
  constructor(
    public id?: number,
    public title?: string,
    public description?: string,
    public parentId?: number,
    public linkName?: string,
  ) { }
}
export class SubCategoryEdit {
  constructor(
    public id?: number,
    public title?: string,
    public description?: string,
    public parentId?: number,
    public linkName?: string,
    public mainImageSrc?: string,
  ) { }
}

export class PostAdd {
  constructor(
    public id?: number,
    public title?: string,
    public description?: string,
    public publicationDate?: number,
    public categoryId?: number,
  ) { }
}
export class PostCreate {
  constructor(
    public id?: number,
    public title?: string,
    public description?: string,
    public publication_date?: number,
    public category_id?: number,
  ) { }
}
export class PostList {
  constructor(
    public id?: number,
    public title?: string,
    public created_at?: string,
    public updated_at?: string,
    public category?: CategoryList,
  ) { }
}

export class PostEdit {
  constructor(
    public id?: number,
    public title?: string,
    public category?: CategoryList,
    public categoryId?: number,
  ) { }
}

export class PostFile {
  constructor(
    public id?: number,
    public filepath?: string,
    public filename?: string,
    public name?: string
  ) { }
}

export class PostLink {
  constructor(
    public id?: number,
    public name?: string,
    public url?: string
  ) { }
}

export interface TraderPosition {
  id?: number;
  title?: string;
  key?: string;
}

export interface TraderNews {
  id?: number;
  is_active?: boolean;
  title?: string;
  description?: string;
  created_at?: string;
  updated_at?: string;
}


export interface TraderSlider {
  id?: number;
  is_active?: boolean;
  title?: string;
  created_at?: string;
  updated_at?: string;
}

export interface TraderSliderItem {
  id?: number,
  title?: string;
  description?: string;
  filepath?: string,
  filename?: string,
  sort_order?: number
}

export interface AddTeam {
  id?: number;
  name?: string;
  surname?: string;
  phone?: string;
  mobile?: string;
  email?: string;
  is_active?:boolean;
  main_image_src?:string;
  order?:number;
  roles_ids?: number[];
  regions_ids?: number[];
}
