import {Injectable, Injector} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const auth = this.injector.get(AuthenticationService);
    if (request.url.indexOf('oauth/token') == -1) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${auth.getToken()}`
        }
      });
    }
    return next.handle(request)
      .do((ev: HttpEvent<any>) => {
        //  something?
      })
      .catch(response => {
        if (response instanceof HttpErrorResponse) {
          if (response.status === 401) {
            auth.logout();
          }
        }
        return Observable.throw(response);
      });
  }
}
