import {Component, ElementRef, OnInit, ViewChild, TemplateRef} from '@angular/core';
import {ContentService} from '../../content.service';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {ToastrService} from 'ngx-toastr';
import {Observable} from 'rxjs/Observable';
import { UserService } from 'app/user/user.service';
import { User } from 'app/user/user.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html'
})
export class FileListComponent implements OnInit {

  displayedColumns = ['filename', 'filetime', 'filepath', 'actions'];
  displayedColumnsProducts = ['productId', 'language', 'productName', 'productDocumentName', 'createdAt' , 'link'];

  dataSource: FileDatasource | null;
  productsDataSource:  ProductsDataSource | null;

  database: FileDatabase;
  productsFilesDatabase: ProductsFilesDatabase;

  modalRef: BsModalRef;
  showModal
  fileNameSelected: string;
  user: User;


  @ViewChild('filter', {static: true}) filter: ElementRef;

  @ViewChild('sortFiles', {static: true}) sortFiles: MatSort;
  @ViewChild('sortProducts', {static: true}) sortProducts: MatSort;


  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @ViewChild('paginatorProduct', {read: MatPaginator, static:true}) paginatorProduct: MatPaginator;

  @ViewChild('productFilesModal') productFilesModal: TemplateRef<any>;

  @ViewChild('myModal') myModal;

  constructor(private toastr: ToastrService, private modalService: BsModalService, private contentService: ContentService,
    private userService: UserService, private spinnerService: NgxSpinnerService) {
  }


  ngOnInit(): void {
    this.userService.getUser().subscribe((user) => {
      if (user != null) {
        this.user = user;
      }
    });


    this.database = new FileDatabase(this.contentService);
    this.dataSource = new FileDatasource(this.database, this.paginator, this.sortFiles)

    this.productsFilesDatabase = new ProductsFilesDatabase(this.contentService, this.fileNameSelected);
    this.productsDataSource = new ProductsDataSource(this.productsFilesDatabase, this.paginatorProduct, this.sortProducts)


    Observable.fromEvent(this.filter.nativeElement, 'keyup')
      .debounceTime(150)
      .distinctUntilChanged()
      .subscribe(() => {
        if (!this.dataSource) {
          return;
        }
        this.dataSource.filter = this.filter.nativeElement.value;
      });
  }
  openProductToEdit(productId: number, languageId: number) {
    this.spinnerService.show();
    this.userService.updateUserLanguage(this.user.id, languageId)
      .subscribe(user => {
          this.userService.dataChange.next(user);
          this.user = user;
          window.open(
            '/#/products/edit/' + productId,
            '_blank'
          );
        },
        () => {},
        () => {
          this.spinnerService.hide();
        });

  }

  openModal() {
    this.showModal = true;
  }

  hideModal() {
    this.showModal = false;
  }

  getFileProductList(filename: string) {
   if (filename) {
     this.fileNameSelected = filename;
     this.contentService.getAssociatedProductsByFilename(filename).subscribe(
      products => {
        this.productsFilesDatabase.updateData(products);
        this.openModal();
      });
    }
  }


  onFileUploaded() {
    this.contentService.getContentFiles().subscribe(
      files => {
        this.database.updateData(files);
      });
  }

  remove(filename: string): void {
    this.contentService.deleteContentFile(filename)
      .subscribe(() => {
          this.toastr.success('File deleted successfully', '');
          this.deleteRowDataTable(filename);
        }
      )
  }

  deleteRowDataTable(filename) {
    const dsData = this.database.data;
    const itemIndex = dsData.findIndex(obj => obj['filename'] === filename);
    dsData.splice(itemIndex, 1)
    this.database.dataChange.next(dsData);
  }

  copyTextToClipboard(text) {
    const txtArea = document.createElement('textarea');

    txtArea.style.position = 'fixed';
    txtArea.style.top = '0';
    txtArea.style.left = '0';
    txtArea.style.opacity = '0';
    txtArea.value = text;
    document.body.appendChild(txtArea);
    txtArea.select();
    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      this.toastr.success('File path copied to the clipboard');
      if (successful) {
        return true;
      }
    } catch (err) {
      this.toastr.success('Ups! Something went wrong :(');
    }
    document.body.removeChild(txtArea);
    return false;
  }
}

export class FileDatabase {
  /** Stream that emits whenever the data has been modified. */
  dataChange: BehaviorSubject<File[]> = new BehaviorSubject<File[]>([]);

  get data(): File[] {
    return this.dataChange.value;
  }


  updateData(files) {
    this.dataChange.next(files);
  }

  constructor(private contentService: ContentService) {
    this.contentService.getContentFiles().subscribe(
      file => {
        this.dataChange.next(file);
      });
  }
}


export class FileDatasource extends DataSource<File> {
  _filterChange = new BehaviorSubject('');

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  prevFilterValue: string;

  constructor(private _database: FileDatabase, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this.prevFilterValue = this.filter.toLowerCase();
  }

  connect(collectionViewer: CollectionViewer): Observable<File[]> {
    const displayDataChanges = [
      this._database.dataChange,
      this._sort.sortChange,
      this._filterChange,
      this._paginator.page
    ];
    return Observable.merge(...displayDataChanges).map(() => {

      const data = this._database.data.slice().filter((item: File) => {
        if (item.filename == null) {
          return false;
        }
        const searchStr = (item.filename).toLowerCase();
        return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
      });

      if (this.filter.toLowerCase() !== this.prevFilterValue) {
        this._paginator.pageIndex = 0;
      }
      this.prevFilterValue = this.filter.toLowerCase();

      // Sort filtered data
      const sortedData = this.sortData(data.slice());

      // Grab the page's slice of data.
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return sortedData.splice(startIndex, this._paginator.pageSize);
    });
  }

  disconnect(collectionViewer: CollectionViewer): void {
  }

  /** Returns a sorted copy of the database data. */
  sortData(data: File[]): File[] {
    if (!this._sort.active || this._sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'filename':
          [propertyA, propertyB] = [a.filename, b.filename];
          break;
        case 'filesize':
          [propertyA, propertyB] = [a.filesize, b.filesize];
          break;
        case 'filetime':
          [propertyA, propertyB] = [a.filetime, b.filetime];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }


}

export class ProductsFilesDatabase {
  /** Stream that emits whenever the data has been modified. */
  dataChange: BehaviorSubject<ProductAttached[]> = new BehaviorSubject<ProductAttached[]>([]);

  get data(): ProductAttached[] {
    return this.dataChange.value;
  }

  updateData(products) {
    this.dataChange.next(products);
  }

  constructor(private contentService: ContentService, private fileName: string) {
    if (fileName) {
     this.contentService.getAssociatedProductsByFilename(fileName).subscribe(
       products => {
        this.dataChange.next(products);
       });
    }

  }
}
export class ProductsDataSource extends DataSource<ProductAttached> {
  _filterChange = new BehaviorSubject('');

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  constructor(private _database: ProductsFilesDatabase, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<ProductAttached[]> {
    const displayDataChanges = [
      this._database.dataChange,
      this._sort.sortChange,
      this._filterChange,
      this._paginator.page
    ];
    return Observable.merge(...displayDataChanges).map(() => {

      const data = this._database.data.slice().filter((item: ProductAttached) => {
        if (item.productId == null) {
          return false;
        }
        const searchStr = (item.productId.toString()).toLowerCase();
        return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
      });

      // Sort filtered data
      const sortedData = this.sortData(data.slice());

      // Grab the page's slice of data.
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return sortedData.splice(startIndex, this._paginator.pageSize);
    });
  }

  disconnect(collectionViewer: CollectionViewer): void {
  }

    /** Returns a sorted copy of the database data. */
    sortData(data: ProductAttached[]): ProductAttached[] {
      if (!this._sort.active || this._sort.direction === '') {
        return data;

      }

      return data.sort((a, b) => {
        let propertyA: number | string = '';
        let propertyB: number | string = '';

        switch (this._sort.active) {
          case 'productId': [propertyA, propertyB] = [a.productId, b.productId]; break;
          case 'language': [propertyA, propertyB] = [a.language, b.language]; break;
          case 'productName': [propertyA, propertyB] = [a.productName, b.productName]; break;
          case 'productDocumentName': [propertyA, propertyB] = [a.productDocumentName, b.productDocumentName]; break;
          case 'createdAt': [propertyA, propertyB] = [a.createdAt, b.createdAt]; break;
      }

        const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
        const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

        return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
      });
    }
  }
  export interface ProductAttached {
    productId: number,
    languageId: number,
    productName: string,
    productDocumentName: string,
    createdAt: string,
    language: string
  }

export interface File {
  filename: string;
  filepath: string;
  filesize: number;
  filetype: string;
  filetime: string;
}
