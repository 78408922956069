import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {User} from '../../user/user.model';
import {ErrorService} from '../../error.service';
import {UserService} from '../../user/user.service';
import {Observable} from 'rxjs/Observable';
import {ApiGetUserResponse, ApiGetUsersResponse} from './api/api-users-responses.model';
import {AddUser} from './add-user.model';

@Injectable()
export class UsersService {

  private headers: HttpHeaders;
  private apiUrl: string;
  private user: User;

  constructor(private http: HttpClient, private userService: UserService, private errorService: ErrorService) {
    this.apiUrl = environment.apiUrl;
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
    this.user = this.userService.getCachedUser();
    this.userService.dataChange.subscribe((user) => {
      if (user != null) {
        this.user = user;
      }
    });
  }

  getUsers(): Observable<User[]> {
    return this.http.get<ApiGetUsersResponse>(this.apiUrl + '/users?langId=' + this.user.languageId)
      .map((response) => response.data)
      .catch(this.errorService.handleError<any>('getUsers'));
  }

  getUser(userId: number): Observable<User> {
    return this.http.get<ApiGetUserResponse>(this.apiUrl + '/users/' + userId + '?langId=' + this.user.languageId)
      .map((response) => response.data)
      .catch(this.errorService.handleError<any>('getUsers'));
  }


  deleteUser(userId: number) {
    return this.http
      .delete(this.apiUrl + '/users/' + userId + '?langId=' + this.user.languageId, {headers: this.headers})
      .catch(this.errorService.handleError<any>('deleteUser'));
  }

  addUser(user: AddUser): Observable<User> {
    return this.http
      .post(this.apiUrl + '/users?langId=' + this.user.languageId, JSON.stringify(user), {headers: this.headers})
      .catch(this.errorService.handleError<any>('addUser'));
  }

  updateUser(userId: number, user: any) {
    return this.http
      .put(this.apiUrl + '/users/' + userId + '?langId=' + this.user.languageId,
        JSON.stringify(user), {headers: this.headers})
      .catch(this.errorService.handleError<any>('updateUser'));
  }
}
