<div class="animated fadeIn">
    <div class="example-container">
      <div class="example-header">
        <mat-form-field floatPlaceholder="never">
          <input matInput #filter placeholder="Filter">
        </mat-form-field>
        <div style="width:5%"></div>
      </div>

      <mat-paginator #paginator [length]="database.data.length" [pageIndex]="0" [pageSize]="50" [pageSizeOptions]="[5, 10, 25, 100]">
      </mat-paginator>

      <mat-table #table [dataSource]="dataSource" matSort>

        <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Id </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.id}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="parent">
          <mat-header-cell *matHeaderCellDef  mat-sort-header> Parent </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span *ngIf="row.parent;else elseEmptyBlock">{{row.parent.title}}</span>
            <ng-template #elseEmptyBlock>-</ng-template>
        </mat-cell>
        </ng-container>
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef  mat-sort-header> Title </mat-header-cell>
          <mat-cell *matCellDef="let row"> <span [innerHTML]="row.title"></span> </mat-cell>
        </ng-container>
        <ng-container matColumnDef="is_active">
          <mat-header-cell *matHeaderCellDef  mat-sort-header> Active </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span *ngIf="row && row.is_active == 1" class="badge badge-success">Active</span>
            <span *ngIf="row && row.is_active == 0" class="badge badge-dark">Inactive</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="order">
          <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 0 0 5%"> Sort order </mat-header-cell>
          <mat-cell *matCellDef="let row" style="flex: 0 0 5%">
            {{row.order}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="created_at">
          <mat-header-cell *matHeaderCellDef  mat-sort-header> Created At </mat-header-cell>
          <mat-cell *matCellDef="let row"> <span> {{row.created_at | date : 'yyyy-MM-dd HH:mm'}} </span> </mat-cell>
        </ng-container>
        <ng-container matColumnDef="updated_at">
          <mat-header-cell *matHeaderCellDef  mat-sort-header> Updated At </mat-header-cell>
          <mat-cell *matCellDef="let row"> <span> {{ row.updated_at | date : 'yyyy-MM-dd HH:mm' }} </span> </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button class="btn btn-sm btn-primary mr-1" type="button" (click)="redirectToEdit(row.id)">
              <i class="fa fa-pencil"></i>
            </button>
            <button class="btn btn-sm btn-danger mr-1" type="button" (click)="openModal(template, row.id)">
              <i class="fa fa-trash"></i>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

    </div>
  </div>

  <ng-template #template>
    <div class="modal-body text-center">
      <p>Do you want to confirm?</p>
      <button type="button" class="btn btn-default" (click)="confirm()">Yes</button>
      <button type="button" class="btn btn-primary" (click)="decline()">No</button>
    </div>
  </ng-template>
