import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { LumenOutputRangeService } from '../lumen-output-range.service';
import { LumenOutputRange } from '../LumenOutputRange.model';
import { LumenOutputRangeItems } from '../LumenOutputRangeItems.model';
import { DatePipe, Location } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-edit-lumen-output-range',
  templateUrl: './edit-lumen-output-range.component.html'
})
export class EditLumenOutputRangeComponent {

  lumenOutputRangeForm: FormGroup;
  lumenOutputRangeId: number;
  lumenOutputRange: LumenOutputRange;
  lumenOutputRangeItems: LumenOutputRangeItems;
  modalRef: BsModalRef;
  editItemForm: FormGroup;

  constructor(private fb: FormBuilder, private lumenOutputRangeService: LumenOutputRangeService, private router: Router,
    private route: ActivatedRoute, private toastr: ToastrService,
    private modalService: BsModalService, private location: Location,
    private datePipe: DatePipe) {
    this.createForm();
    this.route.params.subscribe(params => {
      this.lumenOutputRangeId = parseInt(params['id']);
      lumenOutputRangeService.getSingleLumenOutputRange(this.lumenOutputRangeId).subscribe(
        lumenOutputRange => {
          this.lumenOutputRange = lumenOutputRange;
          this.setFormValues();
        });
    });
  }

  onSubmit() {
    if (this.isValidForm()) {
      const editLumenOutputRange = this.prepareEditLumenOutputRange();
      this.lumenOutputRangeService.updateLumenOutputRange(this.lumenOutputRangeId, editLumenOutputRange)
        .subscribe(
          response => {
          },
          error => {
          },
          () => {
            this.toastr.success('Lumen output range updated successfully');
            this.router.navigate(['./lumen-output-range/list']);
          });
    }
  }

  onCancel() {
    this.location.back();
  }

  createForm() {
    this.lumenOutputRangeForm = this.fb.group({
      name: ['', Validators.required],
      strip: ['', Validators.required],
      lumenOutputRangeItems: [''],
      lumenOutputRangeMinValue: [''],
      lumenOutputRangeMaxValue: ['']
    });
    this.editItemForm = this.fb.group({
      name: ['', Validators.required],
      minIntValue: ['', Validators.required],
      maxIntValue: ['', Validators.required],
    });
  }

  prepareEditLumenOutputRange(): LumenOutputRange {
    const formModel = this.lumenOutputRangeForm.value;

    const lumenOutputRange: LumenOutputRange = {
      id: this.lumenOutputRangeId,
      name: formModel.name as string,
      strip: formModel.strip as number,
       items: null,
    };
    return lumenOutputRange;
  }
  openEditItemModal(template: TemplateRef<any>, item: LumenOutputRangeItems) {
    this.lumenOutputRangeItems = item;
    this.editItemForm.setValue({
      name: this.lumenOutputRangeItems.name,
      minIntValue: this.lumenOutputRangeItems.minIntValue,
      maxIntValue: this.lumenOutputRangeItems.maxIntValue,
    });

    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  opelDeleteItemModal(template: TemplateRef<any>, lumenOutputRangeItems: LumenOutputRangeItems) {
    this.lumenOutputRangeItems = lumenOutputRangeItems;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  confirmItemEdit(): void {
    const lumenOutputRangeName: string = this.editItemForm.value.name;
    const lumenOutputRangeMinIntValue: number = this.editItemForm.value.minIntValue;
    const lumenOutputRangeMaxIntValue: number = this.editItemForm.value.maxIntValue;
    this.lumenOutputRangeService.updateLumenOutputRangeItems(this.lumenOutputRangeItems.id, lumenOutputRangeName, lumenOutputRangeMinIntValue, lumenOutputRangeMaxIntValue)
      .subscribe(
        () => {
          this.toastr.success('Item renamed successfuly', '');
          this.lumenOutputRange.items.find(item => item.id === this.lumenOutputRangeItems.id).name = lumenOutputRangeName;
          this.lumenOutputRange.items.find(item => item.id === this.lumenOutputRangeItems.id).minIntValue = lumenOutputRangeMinIntValue;
          this.lumenOutputRange.items.find(item => item.id === this.lumenOutputRangeItems.id).maxIntValue = lumenOutputRangeMaxIntValue;
          this.lumenOutputRangeItems = null;
        });

    this.modalRef.hide();
  }

  addItem() {
    this.lumenOutputRangeService.addLumenOutputRangeItems(this.lumenOutputRangeId, this.lumenOutputRangeForm.value.lumenOutputRangeItems,
      this.lumenOutputRangeForm.value.lumenOutputRangeMinValue, this.lumenOutputRangeForm.value.lumenOutputRangeMaxValue)
      .subscribe(lumenOutputRangeItems => {
        this.lumenOutputRange.items = lumenOutputRangeItems
      });
    this.lumenOutputRangeForm.patchValue({ lumenOutputRangeItems: '' });
  }

  sortUp(lumenOutputRangeId) {
    this.lumenOutputRangeService.lumenOutputRangeItemsStortUp(lumenOutputRangeId).subscribe(() => {
      this.lumenOutputRangeService.getSingleLumenOutputRange(this.lumenOutputRangeId).subscribe(lumenOutputRange => {
        this.lumenOutputRange.items = lumenOutputRange.items;
        this.setFormValues();
      }
      );
      this.toastr.success('Lumen output moved updated successfully');
    });
  }

  sortDown(lumenOutputRangeId) {
    this.lumenOutputRangeService.lumenOutputRangeItemsStortDown(lumenOutputRangeId).subscribe(() => {
      this.lumenOutputRangeService.getSingleLumenOutputRange(this.lumenOutputRangeId).subscribe(lumenOutputRange => {
        this.lumenOutputRange.items = lumenOutputRange.items;
        this.setFormValues();
      }
      );
      this.toastr.success('Lumen output moved moved successfully');
    });
  }

  clickOnisActive(value: boolean, lumenOutputRangeId: number) {
    this.lumenOutputRangeService.updateLumenOutputRangeItemsIsActive(value, lumenOutputRangeId).subscribe(
      () => {
        this.toastr.success('Item updated', '');
        this.lumenOutputRange.items.find(item => item.id === lumenOutputRangeId).isActive = value;
      }
    );
  }

  confirmItemDelete() {
    this.lumenOutputRangeService.deleteLumenOutputRangeItems(this.lumenOutputRangeItems.id).subscribe(attrib => {
      this.lumenOutputRange.items = this.lumenOutputRange.items.filter(i => i.id !== this.lumenOutputRangeItems.id);
      this.modalRef.hide();
      this.lumenOutputRangeItems = null;
    });
  }

  declineItemEdit(): void {
    this.modalRef.hide();
    this.lumenOutputRangeItems = null;
  }

  declineItemDelete(): void {
    this.modalRef.hide();
    this.lumenOutputRangeItems = null;
  }

  isValidForm() {
    return this.lumenOutputRangeForm.status === 'VALID';
  }

  get name() {
    return this.lumenOutputRangeForm.get('name');
  }

  get strip() {
    return this.lumenOutputRangeForm.get('strip');
  }

  get minIntValue() {
    return this.lumenOutputRangeForm.get('minIntValue');
  }

  get maxIntValue() {
    return this.lumenOutputRangeForm.get('maxIntValue');
  }

  private setFormValues() {
    this.lumenOutputRangeForm.setValue({
      name: this.lumenOutputRange.name as string,
      strip: this.lumenOutputRange.strip as number,
      lumenOutputRangeItems: '',
      lumenOutputRangeMinValue: '',
      lumenOutputRangeMaxValue: ''
    })
  }
}

