<form [formGroup]="categoryForm" (ngSubmit)="onSubmit()" novalidate>

    <div class="animated fadeIn">
        <div class="row">
            <div class="col-md-12">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group">
                                <label for="name">Name</label>
                                <input type="text" class="form-control" id="name" formControlName="name" required>
                                <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
                                    <div *ngIf="name.errors.required">
                                        Name is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="meta_title">Meta title</label>
                                <input type="text" class="form-control" id="meta_title" formControlName="meta_title">
                                <div *ngIf="meta_title.invalid && (meta_title.dirty || meta_title.touched)" class="alert alert-danger">
                                    <div *ngIf="meta_title.errors.required">
                                        Meta title is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="meta_keywords">Meta keywords</label>
                                <input type="text" class="form-control" id="meta_keywords" formControlName="meta_keywords">
                                <div *ngIf="meta_keywords.invalid && (meta_keywords.dirty || meta_keywords.touched)" class="alert alert-danger">
                                    <div *ngIf="meta_keywords.errors.required">
                                        Meta keywords is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="meta_description">Meta description</label>
                                <input type="text" class="form-control" id="meta_description" formControlName="meta_description">
                                <div *ngIf="meta_description.invalid && (meta_description.dirty || meta_description.touched)" class="alert alert-danger">
                                    <div *ngIf="meta_description.errors.required">
                                        Meta description is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="info">Description</label>
                                <angular-tinymce  formControlName='description'></angular-tinymce>
                            </div>
                            <div class="form-group">
                                <label for="info">Parent category</label>
                                <select class="form-control" formControlName="parentCategoryId">
                                    <option value="">None</option>
                                    <option *ngFor="let category of orderedCategories" [value]="category.id">{{category.name}}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <input type="checkbox" id="isActive" name="isActive" formControlName="isActive" /> Active
                            </div>
                        </div>
                    </div>
                    <div class="card">

                        <div class="card-footer">
                            <button type="submit" class="btn btn-sm btn-primary" [disabled]="!isValidForm()">
                                <i class="fa fa-dot-circle-o"></i> Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>