import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductService } from './../../api/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Feature } from '../feature.model';
import { ListProductCategory } from '../../product-list/list-product-category.model';
import { CategoryService } from '../../categories/category.service';
import { ToastrService } from 'ngx-toastr';
import { FeatureItem } from '../featureItem.model';
import { Location } from '@angular/common';
import { Observable } from 'rxjs/Observable';
import { EditFeatureImage } from 'app/views/products/features/featureItem.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-edit-feature-product',
  templateUrl: './edit-feature.component.html'
})
export class EditFeatureComponent implements OnInit {

  featureForm: FormGroup;
  featureId: number;
  feature: Feature;
  categories: Observable<ListProductCategory[]>;
  featureItem: FeatureItem;
  modalRef: BsModalRef;

  editItemForm: FormGroup;

  constructor(private fb: FormBuilder, private productService: ProductService, private categoryService: CategoryService,
    private router: Router, private route: ActivatedRoute, private toastr: ToastrService,
    private modalService: BsModalService, private location: Location) {

  }
  onNotify(image: EditFeatureImage): void {
    this.featureItem.image = image;
  }
  ngOnInit(): void {
    this.createForm();
    this.categories = this.categoryService.getOrderedListProductCategories();
    this.route.params.subscribe(params => {
      this.featureId = parseInt(params['id']);
      this.productService.getFeature(this.featureId).subscribe(feature => {
        this.feature = feature;
        this.setFormValues();
      }
      );
    });
  }

  onSubmit() {
    const editFeature = this.prepareFeature();
    this.productService.updateFeature(this.featureId, editFeature)
      .subscribe(
        response => {
        },
        error => {
        },
        () => {
          this.toastr.success('Category updated successfully');
          this.router.navigate(['./products/features/list']);
        });
  }

  addItem() {
    this.productService.addFeatureItem(this.featureId, this.featureForm.value.featureItem,
      this.featureForm.value.featureItemMinValue, this.featureForm.value.featureItemMaxValue)
      .subscribe(featureItems => {
        this.feature.items = featureItems
      });
    this.featureForm.patchValue({ featureItem: '' });
  }

  opelDeleteItemModal(template: TemplateRef<any>, featureItem: FeatureItem) {
    this.featureItem = featureItem;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  confirmItemDelete() {
    this.productService.deleteFeatureItem(this.featureItem.id).subscribe(attrib => {
      this.feature.items = this.feature.items.filter(i => i.id !== this.featureItem.id);
      this.modalRef.hide();
      this.featureItem = null;
    });
  }

  declineItemDelete(): void {
    this.modalRef.hide();
    this.featureItem = null;
  }

  openEditItemModal(template: TemplateRef<any>, item: FeatureItem) {
    this.featureItem = item;
    this.editItemForm.setValue({
      name: this.featureItem.name,
      minIntValue: this.featureItem.minIntValue,
      maxIntValue: this.featureItem.maxIntValue,
    });

    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  /**
   * update visiblity on site
   * @param  {boolean} value
   * @param  {number} featureItemId
   */
  clickOnIsVisibleOnSite(value: boolean, featureItemId: number) {
    this.productService.updateFeatureItemVisiblityOnSite(value, featureItemId).subscribe(
      () => {
        this.toastr.success('Item updated', '');
        this.feature.items.find(item => item.id === featureItemId).isVisibleOnSite = value;
      }
    );
  }

  /**
   * update visiblility on configurator
   * @param  {boolean} value
   * @param  {number} featureItemId
   */
  clickOnIsVisibleOnConfigurator(value: boolean, featureItemId: number) {
    this.productService.updateFeatureItemVisiblityOnConfigurator(value, featureItemId).subscribe(
      () => {
        this.toastr.success('Item updated', '');
        this.feature.items.find(item => item.id === featureItemId).isVisibleOnConfigurator = value;
      }
    );
  }

  confirmItemEdit(): void {
    const featureName: string = this.editItemForm.value.name;
    const featureMinIntValue: number = this.editItemForm.value.minIntValue;
    const featureMaxIntValue: number = this.editItemForm.value.maxIntValue;
    this.productService.updateFeatureItem(this.featureItem.id, featureName, featureMinIntValue, featureMaxIntValue)
      .subscribe(
        () => {
          this.toastr.success('Item renamed successfuly', '');
          this.feature.items.find(item => item.id === this.featureItem.id).name = featureName;
          this.feature.items.find(item => item.id === this.featureItem.id).minIntValue = featureMinIntValue;
          this.feature.items.find(item => item.id === this.featureItem.id).maxIntValue = featureMaxIntValue;
          this.featureItem = null;
        });

    this.modalRef.hide();
  }

  declineItemEdit(): void {
    this.modalRef.hide();
    this.featureItem = null;
  }

  sortUp(featureItemId) {
    this.productService.featureItemStortUp(featureItemId).subscribe(() => {
      this.productService.getFeature(this.featureId).subscribe(feature => {
        this.feature.items = feature.items;
        this.setFormValues();
      }
      );
      this.toastr.success('FeatureItem moved updated successfully');
    });
  }

  sortDown(featureItemId) {
    this.productService.featureItemStortDown(featureItemId).subscribe(() => {
      this.productService.getFeature(this.featureId).subscribe(feature => {
        this.feature.items = feature.items;
        this.setFormValues();
      }
      );
      this.toastr.success('FeatureItem moved successfully');
    });
  }

  prepareFeature(): Feature {
    const formModel = this.featureForm.value;

    const editFeature: Feature = {
      id: formModel.id as number,
      name: formModel.name as string,
      categoryId: formModel.categoryId as number,
      items: null,
      isRangeFeature: formModel.isRangeFeature,
      rangeUnit: formModel.rangeUnit,
      isActive: formModel.isActive as boolean,
      isVisibleOnSite: formModel.isVisibleOnSite as boolean,
      sortOrder: formModel.sortOrder as number,
    };
    return editFeature;
  }



  createForm() {
    this.featureForm = this.fb.group({
      name: ['', Validators.required],
      isActive: false,
      isVisibleOnSite: false,
      categoryId: ['', Validators.required],
      sortOrder: [0, Validators.required],
      isRangeFeature: '',
      rangeUnit: '',
      featureItem: [''],
      featureItemMinValue: [''],
      featureItemMaxValue: ['']
    });

    this.editItemForm = this.fb.group({
      name: ['', Validators.required],
      minIntValue: ['', Validators.required],
      maxIntValue: ['', Validators.required],
    });
  }

  private setFormValues() {
    this.featureForm.setValue({
      name: this.feature.name as string,
      isActive: this.feature.isActive as boolean,
      isVisibleOnSite: this.feature.isVisibleOnSite as boolean,
      categoryId: this.feature.categoryId,
      isRangeFeature: this.feature.isRangeFeature,
      rangeUnit: this.feature.rangeUnit,
      sortOrder: this.feature.sortOrder,
      featureItem: '',
      featureItemMinValue: '',
      featureItemMaxValue: ''
    })
  }
  onCancel() {
    this.location.back();
  }
  isValidForm() {
    return this.featureForm.status === 'VALID';
  }




  get name() {
    return this.featureForm.get('name');
  }
  get minIntValue() {
    return this.featureForm.get('minIntValue');
  }

  get maxIntValue() {
    return this.featureForm.get('maxIntValue');
  }
  get rangeUnit() {
    return this.featureForm.get('rangeUnit');
  }
  get isRangeFeature() {
    return this.featureForm.get('isRangeFeature').value;
  }
  get categoryId() {
    return this.featureForm.get('categoryId');
  }

}

