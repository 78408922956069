import { AddDistributorUser } from './user-add/AddDistributorUser.model';
import { CategoryEdit } from './category-edit/category-edit.model';

import {forkJoin as observableForkJoin,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import 'rxjs/Rx'
import { AddDistributor } from './distributor-add/AddDistributor.model'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ErrorService } from '../../error.service'
import { User } from '../../user/user.model'
import { UserService } from '../../user/user.service'
import { AddTeam, CategoryAdd, Distributor, DistributorUser, PostAdd, PostEdit, PostFile, PostLink, RegionEdit, RoleEdit, SubCategoryAdd, SubCategoryEdit, TraderHelper, TraderNews, TraderPosition, TraderSlider, TraderSliderItem } from './Distributor.model'
import {
  ApiDistributor,
  ApiGetCategoryListResponse,
  ApiGetDistributorResponse,
  ApiGetDistributorUserResponse
} from './api/api-distributor-responses.model'
import { CountryService } from './country/country.service'
import { Country } from './country/country.model'
import { UserLanguage } from 'app/user/user-language.model';

@Injectable()
export class DistributorService {


  private headers: HttpHeaders
  private apiUrl: string
  private user: User

  constructor (
    private http: HttpClient,
    private userService: UserService,
    private errorService: ErrorService,
    private countryService: CountryService
  ) {
    this.apiUrl = environment.apiUrl
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    this.user = this.userService.getCachedUser()
    this.userService.dataChange.subscribe(user => {
      if (user != null) {
        this.user = user
      }
    })
  }

  getDistributors (): Observable<Distributor[]> {
    return observableForkJoin([
      this.countryService.getCountries(),
      this.http.get(
        this.apiUrl + '/distributorsApi?langId=' + this.user.languageId
      )
    ]).pipe(
      map((data: any[]) => {
        const countries: Country[] = data[0]
        const distributors: ApiDistributor[] = data[1]
        const listDistributors: Distributor[] = []
        for (const distributor of distributors) {
          const countrId: number = Number(distributor.country_id)
          const country: Country = countries.filter(c => c.id === countrId)[0]

          const row = new Distributor(
            distributor.id,
            distributor.name,
            distributor.address,
            distributor.postalCode,
            distributor.city,
            countrId,
            country,
            distributor.voivodeship_id,
            distributor.tel,
            distributor.tel2,
            distributor.email,
            distributor.www,
            distributor.type,
            distributor.latitude,
            distributor.longitude,
            distributor.company,
            distributor.isActive,
            distributor.is_visible_www
          )
          listDistributors.push(row)
        }
        return listDistributors
      }),
      catchError(this.errorService.handleError<any>('getDistributors')),)
  }
  getDistributorsUsers (): Observable<DistributorUser[]> {
    return observableForkJoin([
      this.countryService.getCountries(),
      this.http.get(
        this.apiUrl + '/trader/distributors?langId=' + this.user.languageId
      )
    ]).pipe(
      map((data: any[]) => {
        const countries: Country[] = data[0]
        const distributors: ApiDistributor[] = data[1]
        const listDistributors: Distributor[] = []
        for (const distributor of distributors) {
          const countrId: number = Number(distributor.country_id)
          const country: Country = countries.filter(c => c.id === countrId)[0]

          const row = new Distributor(
            distributor.id,
            distributor.name,
            distributor.address,
            distributor.postalCode,
            distributor.city,
            countrId,
            country,
            distributor.voivodeship_id,
            distributor.tel,
            distributor.tel2,
            distributor.email,
            distributor.www,
            distributor.type,
            distributor.latitude,
            distributor.longitude,
            distributor.company,
            distributor.isActive,
            distributor.is_visible_www
          )
          listDistributors.push(row)
        }
        return listDistributors
      }),
      catchError(this.errorService.handleError<any>('getDistributors')),)
  }

  addDistributor (distributor: AddDistributor): Observable<AddDistributor> {
    return this.http
      .post(
        this.apiUrl + '/distributorsApi?langId=' + this.user.languageId,
        JSON.stringify(distributor),
        { headers: this.headers }
      ).pipe(
      catchError(this.errorService.handleError<any>('addDistributor')))
  }
  addDistributorUser (distributor: AddDistributorUser): Observable<AddDistributorUser> {
    return this.http
      .post(
        this.apiUrl + '/trader/user/create?langId=' + this.user.languageId,
        JSON.stringify(distributor),
        { headers: this.headers }
      ).pipe(
      catchError(this.errorService.handleError<any>('addDistributorUser')))
  }
  addTeamUser (distributor: AddTeam): Observable<AddTeam> {
    return this.http
      .post(
        this.apiUrl + '/trader/teams/create?langId=' + this.user.languageId,
        JSON.stringify(distributor),
        { headers: this.headers }
      ).pipe(
      catchError(this.errorService.handleError<any>('addTeamUser')))
  }

  getDistributor (distributorId: number): Observable<Distributor> {
    return this.http
      .get(
        this.apiUrl +
          '/distributorsApi/' +
          distributorId +
          '?langId=' +
          this.user.languageId
      ).pipe(
      map(response => {
        const distributor = new ApiGetDistributorResponse(response).getData()
        return new Distributor(
          distributor.id,
          distributor.name,
          distributor.address,
          distributor.postalCode,
          distributor.city,
          distributor.country_id,
          null,
          distributor.voivodeship_id,
          distributor.tel,
          distributor.tel2,
          distributor.email,
          distributor.www,
          distributor.type,
          distributor.latitude,
          distributor.longitude,
          distributor.company,
          distributor.isActive,
          distributor.is_visible_www
        )
      }),
      catchError(this.errorService.handleError<any>('getDistributor')),)
  }
  getUser (distributorId: number): Observable<AddDistributorUser> {
    return this.http
      .get(
        this.apiUrl +
          '/trader/distributor/edit/' +
          distributorId +
          '?langId=' +
          this.user.languageId
      ).pipe(
      catchError(this.errorService.handleError<any>('getUser')),)
  }

  updateDistributor (
    distributorId: number,
    distributor: Distributor
  ): Observable<ApiGetDistributorResponse> {
    return this.http
      .put(
        this.apiUrl +
          '/distributorsApi/' +
          distributorId +
          '?langId=' +
          this.user.languageId,
        JSON.stringify(distributor),
        { headers: this.headers }
      ).pipe(
      catchError(this.errorService.handleError<any>('updateDistributor')))
  }

  updateDistributorUser (
    userId: number,
    user: DistributorUser
  ): Observable<ApiGetDistributorResponse> {
    return this.http
      .put(
        this.apiUrl +
          '/trader/user/' +
          userId +
          '?langId=' +
          this.user.languageId,
        JSON.stringify(user),
        { headers: this.headers }
      ).pipe(
      catchError(this.errorService.handleError<any>('updateDistributor')))
  }

  updateTeamUser (
    userId: number,
    user: AddTeam
  ): Observable<ApiGetDistributorResponse> {
    return this.http
      .put(
        this.apiUrl +
          '/trader/teams/' +
          userId +
          '?langId=' +
          this.user.languageId,
        JSON.stringify(user),
        { headers: this.headers }
      ).pipe(
      catchError(this.errorService.handleError<any>('updateTeamUser')))
  }

  deleteDistributor (distributorId: number) {
    return this.http
      .delete(
        this.apiUrl +
          '/distributorsApi/' +
          distributorId +
          '?langId=' +
          this.user.languageId,
        { headers: this.headers }
      ).pipe(
      catchError(this.errorService.handleError<any>('updateDistributor')))
  }

  deleteUser (distributorId: number) {
    return this.http
      .delete(
        this.apiUrl +
          '/trader/distributor/' +
          distributorId +
          '?langId=' +
          this.user.languageId,
        { headers: this.headers }
      ).pipe(
      catchError(this.errorService.handleError<any>('deleteUser')))
  }
  deleteCategory(categoryId: number) {
    return this.http
      .delete(
        this.apiUrl +
          '/trader/category/delete/' + categoryId + '?langId=' + this.user.languageId,
        { headers: this.headers }
      ).pipe(
      catchError(this.errorService.handleError<any>('deleteCategory')))
  }
  deleteRegion(regionId: number) {
    return this.http
      .delete(
        this.apiUrl +
          '/trader/region/delete/' + regionId + '?langId=' + this.user.languageId,
        { headers: this.headers }
      ).pipe(
      catchError(this.errorService.handleError<any>('deleteRegion')))
  }
  deleteRole(roleId: number) {
    return this.http
      .delete(
        this.apiUrl +
          '/trader/roles/delete/' + roleId + '?langId=' + this.user.languageId,
        { headers: this.headers }
      ).pipe(
      catchError(this.errorService.handleError<any>('deleteRole')))
  }
  deleteNews(newsId: number) {
    return this.http
      .delete(
        this.apiUrl +
          '/trader/news/delete/' + newsId + '?langId=' + this.user.languageId,
        { headers: this.headers }
      ).pipe(
      catchError(this.errorService.handleError<any>('deleteNews')))
  }
  deletePost(postId: number) {
    return this.http
      .delete(
        this.apiUrl +
          '/trader/post/delete/' + postId + '?langId=' + this.user.languageId,
        { headers: this.headers }
      ).pipe(
      catchError(this.errorService.handleError<any>('deletePost')))
  }

  addCategory(categoryAdd: CategoryAdd): Observable<CategoryAdd> {
    return this.http
      .post(this.apiUrl + '/trader/category/create?langId=' + this.user.languageId, JSON.stringify(categoryAdd), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('CategoryAdd')));
  }

  addRegion(regionAdd: RegionEdit): Observable<RegionEdit> {
    return this.http
      .post(this.apiUrl + '/trader/region/create?langId=' + this.user.languageId, JSON.stringify(regionAdd), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('addRegion')));
  }
  addRole(roleAdd: RoleEdit): Observable<RoleEdit> {
    return this.http
      .post(this.apiUrl + '/trader/roles/create?langId=' + this.user.languageId, JSON.stringify(roleAdd), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('addRole')));
  }
  addPost(data: PostAdd): Observable<PostAdd> {
    return this.http
      .post(this.apiUrl + '/trader/post/create?langId=' + this.user.languageId, JSON.stringify(data), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('addPost')));
  }
  addSubCategory(SubCategoryAdd: SubCategoryAdd): Observable<SubCategoryAdd> {
    return this.http
      .post(this.apiUrl + '/trader/sub-category/create?langId=' + this.user.languageId, JSON.stringify(SubCategoryAdd), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('SubCategoryAdd')));
  }
  getAllCategories(): Observable<any[]> {
    return this.http.get<any>(this.apiUrl + '/trader/category/list?langId=' + this.user.languageId).pipe(
      catchError(this.errorService.handleError<any>('getAllCategories')),);
  }
  getAllRegions(): Observable<any[]> {
    return this.http.get<any>(this.apiUrl + '/trader/region/list?langId=' + this.user.languageId).pipe(
      catchError(this.errorService.handleError<any>('getAllRegions')),);
  }
  getAllRoles(): Observable<any[]> {
    return this.http.get<any>(this.apiUrl + '/trader/roles/list?langId=' + this.user.languageId).pipe(
      catchError(this.errorService.handleError<any>('getAllRoles')),);
  }
  getAllPost(): Observable<any[]> {
    return this.http.get<any>(this.apiUrl + '/trader/post/list?langId=' + this.user.languageId).pipe(
      catchError(this.errorService.handleError<any>('getAllPost')),);
  }
  getAllSubCategories(): Observable<any[]> {
    return this.http.get<ApiGetCategoryListResponse>(this.apiUrl + '/trader/sub-category/list?langId=' + this.user.languageId).pipe(
      map((response: ApiGetCategoryListResponse) => {
        return response;
      }),
      catchError(this.errorService.handleError<any>('getAllCategories')),);
  }

  getLinkNames(): Observable<any[]> {
    return this.http.get<any>(this.apiUrl + '/trader/category/link-names?langId=' + this.user.languageId).pipe(
      map(response => {
        return response;
      }),
      catchError(this.errorService.handleError<any>('getLinkNames')),);
  }

  getLanguages(): Observable<UserLanguage[]> {
    return this.http.get<UserLanguage>(this.apiUrl + '/languages').pipe(
      map((response) => response),
      catchError(this.errorService.handleError<any>('getLanguages')),);
  }

  getCategory(categoryId: number): Observable<CategoryEdit> {
    return this.http.get<any>(this.apiUrl + '/trader/category/edit/' + categoryId + '?langId=' + this.user.languageId).pipe(
      map(response => {
        return {
          isActive: response.is_active,
          title: response.title,
          name: response.name,
          description: response.description,
        };
      }),
      catchError(this.errorService.handleError<any>('getArticle')),);
  }
  getRegion(regionId: number): Observable<RegionEdit> {
    return this.http.get<any>(this.apiUrl + '/trader/region/edit/' + regionId + '?langId=' + this.user.languageId).pipe(
      map(response => {
        return {
          title: response.title,
        };
      }),
      catchError(this.errorService.handleError<any>('getRegion')),);
  }
  getRole(roleId: number): Observable<RegionEdit> {
    return this.http.get<any>(this.apiUrl + '/trader/roles/edit/' + roleId + '?langId=' + this.user.languageId).pipe(
      map(response => {
        return {
          title: response.title,
        };
      }),
      catchError(this.errorService.handleError<any>('getRole')),);
  }
  getPost(postId: number): Observable<PostEdit> {
    return this.http.get<any>(this.apiUrl + '/trader/post/edit/' + postId + '?langId=' + this.user.languageId).pipe(
      map(response => {
        return {
          id: response.id,
          isActive: response.is_active,
          isMain: response.is_main,
          linkName: response.link_name,
          title: response.title,
          categoryId: response.category_id,
          category: response.category,
          publicationDate: response.publication_date,
          description: response.description,
          mainImageSrc: response.main_image_src,
          files: response.files,
          links: response.links
        };
      }),
      catchError(this.errorService.handleError<any>('getArticle')),);
  }
  getSubCategory(categoryId: number): Observable<SubCategoryEdit> {
    return this.http.get<any>(this.apiUrl + '/trader/category/edit/' + categoryId + '?langId=' + this.user.languageId).pipe(
      map(response => {
        return {
          id: response.id,
          isActive: response.is_active,
          title: response.title,
          order: response.order,
          description: response.description,
          linkName: response.link_name,
          parentId: response.parent_id,
          mainImageSrc: response.main_image_src
        };
      }),
      catchError(this.errorService.handleError<any>('getArticle')),);
  }

  updateCategory(data:any, categoryId:number){
    return this.http
      .put(this.apiUrl + '/trader/category/update/' + categoryId + '?langId=' + this.user.languageId,
        JSON.stringify(data), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('updateCategory')));
  }
  updateRegion(data:any, regionId:number){
    return this.http
      .put(this.apiUrl + '/trader/region/update/' + regionId + '?langId=' + this.user.languageId,
        JSON.stringify(data), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('updateRegion')));
  }
  updateRole(data:any, roleId:number){
    return this.http
      .put(this.apiUrl + '/trader/roles/update/' + roleId + '?langId=' + this.user.languageId,
        JSON.stringify(data), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('updateRole')));
  }

  updateTraderFile(file: PostFile){
    return this.http
      .put(this.apiUrl + '/trader/post/file/' + file.id + '?langId=' + this.user.languageId,
        JSON.stringify(file), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('updateTraderFile')));
  }

  updatePostLink(link: PostLink, postId: number){
    const body = {
      post_id: postId,
      name: link.name,
      url: link.url,
    };
    return this.http
      .put(this.apiUrl + '/trader/post/link/' + link.id + '?langId=' + this.user.languageId,
        JSON.stringify(body), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('updateTraderFile')));
  }

  updateSubCategory(data:any, categoryId:number){
    return this.http
      .put(this.apiUrl + '/trader/sub-category/update/' + categoryId + '?langId=' + this.user.languageId,
        JSON.stringify(data), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('updateCategory')));
  }


  attachSubCategoryMainImage(fileObject: any, categoryId: number): Observable<SubCategoryEdit> {
    const body: any = {
      categoryId: categoryId,
      languageId: this.user.languageId,
      filename: fileObject.filename,
      filepath: fileObject.filepath
    }
    return this.http
      .post<any>(this.apiUrl + '/trader/sub-category/attachMainImage?langId=' + this.user.languageId,
        JSON.stringify(body), { headers: this.headers }).pipe(
      map(response => {
        return {
          id: response.id,
          isActive: response.is_active,
          title: response.title,
          description: response.description,
          linkName: response.link_name,
          parentId: response.parent_id,
          mainImageSrc: response.main_image_src
        };
      }),
      catchError(this.errorService.handleError<any>('attachSubCategoryMainImage')),);
  }

  attachPostMainImage(fileObject: any, postId: number): Observable<SubCategoryEdit> {
    const body: any = {
      postId: postId,
      languageId: this.user.languageId,
      filename: fileObject.filename,
      filepath: fileObject.filepath
    }
    return this.http
      .post<any>(this.apiUrl + '/trader/post/attachMainImage?langId=' + this.user.languageId,
        JSON.stringify(body), { headers: this.headers }).pipe(
      map(response => {
        return {
          id: response.id,
          isActive: response.is_active,
          title: response.title,
          categoryId: response.category_id,
          category: response.category,
          publicationDate: response.publication_date,
          description: response.description,
          mainImageSrc: response.main_image_src,
          links: response.links
        };
      }),
      catchError(this.errorService.handleError<any>('attachSubCategoryMainImage')),);
  }

  attachPostFile(fileObject: any, postId: number): Observable<PostFile[]> {
    const body: any = {
      postId: postId,
      languageId: this.user.languageId,
      filename: fileObject.filename,
      filepath: fileObject.filepath
    }
    return this.http
      .post<any>(this.apiUrl + '/trader/post/attachFile?langId=' + this.user.languageId,
        JSON.stringify(body), { headers: this.headers }).pipe(
      map(response => {
        return response;
      }),
      catchError(this.errorService.handleError<any>('attachPostFile')),);
  }

  attachUserMainImage(fileObject: any, userId: number): Observable<DistributorUser> {
    const body: any = {
      userId: userId,
      languageId: this.user.languageId,
      filename: fileObject.filename,
      filepath: fileObject.filepath
    }
    return this.http
      .post<any>(this.apiUrl + '/trader/user/attachFile?langId=' + this.user.languageId,
        JSON.stringify(body), { headers: this.headers }).pipe(
      map(response => {
        return response;
      }),
      catchError(this.errorService.handleError<any>('attachUserMainImage')),);
  }

  attachTeamMainImage(fileObject: any, userId: number): Observable<AddTeam> {
    const body: any = {
      userId: userId,
      languageId: this.user.languageId,
      filename: fileObject.filename,
      filepath: fileObject.filepath
    }
    return this.http
      .post<any>(this.apiUrl + '/trader/teams/attachFile?langId=' + this.user.languageId,
        JSON.stringify(body), { headers: this.headers }).pipe(
      map(response => {
        return response;
      }),
      catchError(this.errorService.handleError<any>('attachTeamMainImage')),);
  }

  detachSubCategoryMainImage(categoryId: number): Observable<SubCategoryEdit> {
    const body: any = {
      categoryId: categoryId,
      languageId: this.user.languageId,
    }
    return this.http
      .post<any>(this.apiUrl + '/trader/sub-category/detachSubCategoryMainImage?langId=' + this.user.languageId,
        JSON.stringify(body), { headers: this.headers }).pipe(
      map(response => {
        return response.data;
      }),
      catchError(this.errorService.handleError<any>('detachSubCategoryMainImage')),);
  }

  detachPostMainImage(postId: number): Observable<SubCategoryEdit> {
    const body: any = {
      postId: postId,
      languageId: this.user.languageId,
    }
    return this.http
      .post<any>(this.apiUrl + '/trader/post/detachPostMainImage?langId=' + this.user.languageId,
        JSON.stringify(body), { headers: this.headers }).pipe(
      map(response => {
        return response;
      }),
      catchError(this.errorService.handleError<any>('detachPostMainImage')),);
  }
  detachUserMainImage(userId: number): Observable<SubCategoryEdit> {
    const body: any = {
      userId: userId,
      languageId: this.user.languageId,
    }
    return this.http
      .post<any>(this.apiUrl + '/trader/user/detachMainImage?langId=' + this.user.languageId,
        JSON.stringify(body), { headers: this.headers }).pipe(
      map(response => {
        return response;
      }),
      catchError(this.errorService.handleError<any>('detachPostMainImage')),);
  }
  detachTeamMainImage(userId: number): Observable<AddTeam> {
    const body: any = {
      userId: userId,
      languageId: this.user.languageId,
    }
    return this.http
      .post<any>(this.apiUrl + '/trader/teams/detachMainImage?langId=' + this.user.languageId,
        JSON.stringify(body), { headers: this.headers }).pipe(
      map(response => {
        return response;
      }),
      catchError(this.errorService.handleError<any>('detachTeamMainImage')),);
  }
  detachPostFile(postId: number, id: number): Observable<PostFile[]> {
    const body: any = {
      id: id,
      postId: postId,
      languageId: this.user.languageId,
    }
    return this.http
      .post<any>(this.apiUrl + '/trader/post/detachPostFile?langId=' + this.user.languageId,
        JSON.stringify(body), { headers: this.headers }).pipe(
      map(response => {
        return response;
      }),
      catchError(this.errorService.handleError<any>('detachPostFile')),);
  }
  detachPostLink(id: number,  postId:number): Observable<PostLink[]> {
    const body: any = {
      id: id,
      postId: postId,
    };
    return this.http
      .post<any>(this.apiUrl + '/trader/post/link/detach?langId=' + this.user.languageId,
        JSON.stringify(body), { headers: this.headers }).pipe(
      map(response => {
        return response;
      }),
      catchError(this.errorService.handleError<any>('detachPostFile')),);
  }

  addPostLink(formModel, postId:number) {
    const body: any = {
      post_id: postId,
      name: formModel.linkName,
      url: formModel.linkUrl
    };
    return this.http
      .post<any>(this.apiUrl + '/trader/post/link/create?langId=' + this.user.languageId,
        JSON.stringify(body), { headers: this.headers }).pipe(
      map(response => {
        return {
          id: response.id,
          isActive: response.is_active,
          title: response.title,
          categoryId: response.category_id,
          category: response.category,
          publicationDate: response.publication_date,
          description: response.description,
          mainImageSrc: response.main_image_src,
          files: response.files,
          links: response.links
        };
      }),
      catchError(this.errorService.handleError<any>('addPostLink')),);
  }

  updatePost(data: any, postId: number): Observable<PostEdit> {
    return this.http
      .put<any>(this.apiUrl + '/trader/post/update/' + postId + '?langId=' + this.user.languageId,
        JSON.stringify(data), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('updatePost')));
  }

  getPositions(): Observable<TraderPosition[]> {
    return this.http
    .get<TraderPosition>(this.apiUrl + '/trader/positions?langId=' + this.user.languageId,{ headers: this.headers }).pipe(
    catchError(this.errorService.handleError<any>('getPositions')));
  }

  getMenagers(role:string): Observable<User[]> {
    return this.http
    .get<User[]>(this.apiUrl + '/trader/menagers?role=' + role,{ headers: this.headers }).pipe(
    catchError(this.errorService.handleError<any>('getMenagers')));
  }

  getUserFiles(): Observable<any[]> {
    return this.http.get<any>(this.apiUrl + '/trader/user/files?langId=' + this.user.languageId).pipe(
      catchError(this.errorService.handleError<any>('getUserFiles')));
  }

  deleteUserFile(fileName: string) {
    if (confirm('Do you really want to delete this file ? (related documents may be deleted)')) {
      return this.http
        .post(this.apiUrl + '/trader/user/files/delete', { filename: fileName }, { headers: this.headers }).pipe(
        catchError(this.errorService.handleError<any>('deleteDistributor')));
    }
  }
  addNews(newsAdd: TraderNews): Observable<TraderNews> {
    return this.http
    .post(this.apiUrl + '/trader/news/create?langId=' + this.user.languageId, JSON.stringify(newsAdd), { headers: this.headers }).pipe(
    catchError(this.errorService.handleError<any>('addNews')));
  }

  getAllNews(): Observable<TraderNews[]> {
    return this.http.get<TraderNews[]>(this.apiUrl + '/trader/news?langId=' + this.user.languageId).pipe(
      catchError(this.errorService.handleError<any>('getAllNews')),);
  }

  getNews(newsId: number): Observable<TraderNews> {
    return this.http.get<TraderNews>(this.apiUrl + '/trader/news/edit/'+newsId+'?langId=' + this.user.languageId).pipe(
      catchError(this.errorService.handleError<any>('getNews')),);
  }

  updateNews(news: TraderNews, newsId: number) {
    return this.http
      .put<any>(this.apiUrl + '/trader/news/update/' + newsId + '?langId=' + this.user.languageId,
        JSON.stringify(news), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('updateNews')));
  }
  getAllSliders(): Observable<TraderSlider[]> {
    return this.http.get<TraderSlider[]>(this.apiUrl + '/trader/slider/list?langId=' + this.user.languageId).pipe(
      catchError(this.errorService.handleError<any>('getAllSliders')),);
  }
  getSlider(sliderId: number): Observable<TraderSlider> {
    return this.http.get<TraderSlider>(this.apiUrl + '/trader/slider/edit/'+sliderId+'?langId=' + this.user.languageId).pipe(
      catchError(this.errorService.handleError<any>('getSlider')),);
  }

  attachSliderItem(fileObject: any, sliderId: number) {
    const body: any = {
      sliderId: sliderId,
      languageId: this.user.languageId,
      filename: fileObject.filename,
      filepath: fileObject.filepath
    }
    return this.http
      .post<any>(this.apiUrl + '/trader/slider/attachSliderItem?langId=' + this.user.languageId,
        JSON.stringify(body), { headers: this.headers }).pipe(
      map(response => {
        return response;
      }),
      catchError(this.errorService.handleError<any>('attachSliderItem')),);
  }

  detachSliderItem(sliderId: number,  itemId:number): Observable<PostLink[]> {
    const body: any = {
      sliderId: sliderId,
      itemId: itemId,
    };
    return this.http
      .post<any>(this.apiUrl + '/trader/slider/detach?langId=' + this.user.languageId,
        JSON.stringify(body), { headers: this.headers }).pipe(
      map(response => {
        return response;
      }),
      catchError(this.errorService.handleError<any>('detachSliderItem')),);
  }
  updateSliderItemFile(selectedItem: TraderSliderItem) {
    return this.http
      .put(this.apiUrl + '/trader/slider/item/' + selectedItem.id + '?langId=' + this.user.languageId,
        JSON.stringify(selectedItem), { headers: this.headers }).pipe(
      catchError(this.errorService.handleError<any>('updateSliderItemFile')));
  }

  updateSlider(data: any, sliderId: number) {
    return this.http
    .put<any>(this.apiUrl + '/trader/slider/update/' + sliderId + '?langId=' + this.user.languageId,
      JSON.stringify(data), { headers: this.headers }).pipe(
    catchError(this.errorService.handleError<any>('updateSlider')));
  }

  categorySortUp(categoryId: number, parentId: number) {
    return this.http.put(this.apiUrl + '/trader/sub-category/sortUp/' + categoryId + '/' + parentId, {}, { headers: this.headers }).pipe(
      map(() => { }),
      catchError(this.errorService.handleError<any>('categorySortUp')),);
  }

  categorySortDown(categoryId: number, parentId: number) {
    return this.http.put(this.apiUrl + '/trader/sub-category/sortDown/' + categoryId + '/' + parentId, {}, { headers: this.headers }).pipe(
      map(() => { }),
      catchError(this.errorService.handleError<any>('categorySortDown')),);
  }

  getTeamUsers(): Observable<TraderHelper[]> {
    return this.http.get<TraderHelper[]>(this.apiUrl + '/trader/teams?langId=' + this.user.languageId).pipe(
      catchError(this.errorService.handleError<any>('getAllNews')),);
  }
  deleteTeam (userId: number) {
    return this.http
      .delete(
        this.apiUrl +
          '/trader/teams/' +
          userId +
          '?langId=' +
          this.user.languageId,
        { headers: this.headers }
      ).pipe(
      catchError(this.errorService.handleError<any>('deleteTeam')))
  }

  getTeamUser(userId: number): Observable<AddTeam> {
      return this.http
        .get(
          this.apiUrl +
            '/trader/teams/edit/' +
            userId +
            '?langId=' +
            this.user.languageId
        ).pipe(
        catchError(this.errorService.handleError<any>('userId')),)
  }

  getTeamRoles(): Observable<any[]> {
    return this.http.get(this.apiUrl + '/trader/teams/roles/list?langId=' + this.user.languageId).pipe(
      catchError(this.errorService.handleError<any>('getTeamRoles')),);
  }
  getTeamRegions(): Observable<any[]> {
    return this.http.get(this.apiUrl + '/trader/region/list?langId=' + this.user.languageId).pipe(
      catchError(this.errorService.handleError<any>('getTeamRegions')),);
  }
}
