export class Helpers {

    /**
     * Set history in html element
     * @param  {any} product
     * @returns any
     */
    setHistoryRecords(product: any): any {

        var lastInTechnicalDrawing = document.getElementById('lastInTechnicalDrawing');
          if (typeof lastInTechnicalDrawing != undefined && lastInTechnicalDrawing != null) {
            lastInTechnicalDrawing.innerHTML = this.getLastFromHistory('PRODUCT_TECHNICAL_DRAWING_IMAGES', product);
         }    
  
      var lastInAttributes = document.getElementById('lastInAttributes');
  
      if (typeof lastInAttributes != undefined && lastInAttributes != null) {
          lastInAttributes.innerHTML = this.getLastFromHistory('PRODUCT_ATTRIBUTE_ACTIONS', product);
      }
  
      var lastInSpecifications =  document.getElementById('lastInSpecifications');
      if (typeof lastInSpecifications != undefined && lastInSpecifications != null) {
        lastInSpecifications.innerHTML = this.getLastFromHistory('PRODUCT_SPECIFICATION_ACTIONS', product);
      }  
      
      var lastInAlbumProducts = document.getElementById('lastInAlbumProducts');
      if(typeof lastInAlbumProducts != undefined && lastInAlbumProducts != null){
        lastInAlbumProducts.innerHTML = this.getLastFromHistory('PRODUCT_ALBUM_IMAGES', product);
      }
  
      var lastInAlbumUsages = document.getElementById('lastInAlbumUsages');
      if(typeof lastInAlbumUsages != undefined && lastInAlbumUsages != null){
        lastInAlbumUsages.innerHTML = this.getLastFromHistory('PRODUCT_ALBUM_USAGE_IMAGES', product);
      }
  
      var lastInAlbumDocuments = document.getElementById('lastInAlbumDocuments');
      if(typeof lastInAlbumDocuments != undefined && lastInAlbumDocuments != null){
        lastInAlbumDocuments.innerHTML = this.getLastFromHistory('PRODUCT_DOCUMENTS_IMAGES', product);
      }
  
      var lastInCouplersAndAuto = document.getElementById('lastInCouplersAndAuto');
      if(typeof lastInCouplersAndAuto != undefined && lastInCouplersAndAuto != null){
        lastInCouplersAndAuto.innerHTML = this.getLastFromHistory('PRODUCT_COUPLERS_AND_AUTO_UPDATES', product);
      }
  
      var lastInFeatures = document.getElementById('lastInFeatures');
      if(typeof lastInFeatures != undefined && lastInFeatures != null){
        lastInFeatures.innerHTML = this.getLastFromHistory('PRODUCT_FEATURES_UPDATES', product);
      }
  
      var lastInRelations = document.getElementById('lastInRelations');
      if(typeof lastInRelations != undefined && lastInRelations != null){
        lastInRelations.innerHTML = this.getLastFromHistory('PRODUCT_RELATES_UPDATES', product);
      }
  
      var lastInUpdates = document.getElementById('lastInUpdates');
      if(typeof lastInUpdates != undefined && lastInUpdates != null){
        lastInUpdates.innerHTML = this.getLastFromHistory('PRODUCT_OTHER_UPDATES', product);
      }
  
    }
  
  
    /**
     * Find and return last modified
     * @param  {string} action
     * @param  {any} product
     * @returns string
     */
    getLastFromHistory(action: string, product: any): string {
      if (product.historyRecords.data.length > 0) {
        var data = product.historyRecords.data.filter(sr => sr.action == action);
  
        if (data.length > 0) {
  
          var sorted = data.sort((a, b) => {
            if (a.createdOn < b.createdOn) {
              return 1;
            } else if (a.createdOn > b.createdOn) {
              return -1;
            }
            return 0;
          });
  
          if (typeof sorted[0] != undefined) {
            let choosed = sorted[0];
  
            let createdOn = choosed.createdOn || '';
            let user = choosed.user || 'no history';
  
            return createdOn + ' ' + user;
  
          } else {
            return 'no history';
          }
  
        } else {
          return 'no history';
        }
  
      } else {
        return 'no history';
      }
  
    }
  
  }
  