<form
  [formGroup]="distributorForm"
  (ngSubmit)="onSubmit()"
  novalidate
  #dform="ngForm"
>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <label for="name">Company</label>
              <input
                type="text"
                class="form-control"
                id="company"
                formControlName="company"
              />
              <div
                *ngIf="
                  company.invalid &&
                  (company.dirty || company.touched || dform.submitted)
                "
                class="alert alert-danger"
              >
                <div *ngIf="company.errors.required">Company is required.</div>
              </div>
            </div>

            <div class="form-group">
              <label for="type">Type</label>
              <select class="form-control" formControlName="type" id="type">
                <option value="0">Distributor</option>
                <option value="1">Representative</option>
                <option value="2">Subpartner</option>
                <option value="3">DIY</option>
                <option value="4">A/V Channel Reps</option>
                <option value="5">On-Line Partners</option>
                <option value="6">International Reps</option>
              </select>
            </div>

            <div class="row">
              <div class="form-group col-sm-6">
                <label for="name">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  formControlName="name"
                />
                <div
                  *ngIf="
                    name.invalid &&
                    (name.dirty || name.touched || dform.submitted)
                  "
                  class="alert alert-danger"
                >
                  <div *ngIf="name.errors.required">Name is required.</div>
                </div>
              </div>

              <div class="form-group col-sm-6">
                <label for="surname">Surname</label>
                <input
                  type="text"
                  class="form-control"
                  id="surname"
                  formControlName="surname"
                />
                <div
                  *ngIf="
                    surname.invalid &&
                    (surname.dirty || surname.touched || dform.submitted)
                  "
                  class="alert alert-danger"
                >
                  <div *ngIf="surname.errors.required">
                    Surname is required.
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="trader_position_id">Position</label>

              <select
                class="form-control mw-50"
                formControlName="trader_position_id"
                id="trader_position_id"
                required="true"
              >
                <option
                  *ngFor="let position of positions"
                  [value]="position.id"
                >
                  {{ position.title }}
                </option>
              </select>
              <div
                *ngIf="
                trader_position_id.invalid &&
                  (trader_position_id.dirty || trader_position_id.touched || dform.submitted)
                "
                class="alert alert-danger"
              >
                <div *ngIf="trader_position_id.errors.required">
                  Position is required.
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="address">Address</label>
              <input
                type="text"
                class="form-control"
                id="address"
                formControlName="address"
              />
              <div
                *ngIf="
                  address.invalid &&
                  (address.dirty || address.touched || dform.submitted)
                "
                class="alert alert-danger"
              >
                <div *ngIf="address.errors.required">Address is required.</div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-8">
                <label for="name">City</label>
                <input
                  type="text"
                  class="form-control"
                  id="city"
                  formControlName="city"
                />
              </div>
              <div class="form-group col-sm-4">
                <label for="name">Postal code</label>
                <input
                  type="text"
                  class="form-control"
                  id="postalCode"
                  formControlName="postalCode"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-4">
                <label for="countryId">Country</label>
                <select
                  class="form-control"
                  id="countryId"
                  formControlName="country_id"
                >
                  <option value="">None</option>
                  <option
                    *ngFor="let country of countries"
                    [value]="country.id"
                  >
                    {{ country.name }}
                  </option>
                </select>
                <div
                  *ngIf="
                    country_id.invalid &&
                    (country_id.dirty || country_id.touched || dform.submitted)
                  "
                  class="alert alert-danger"
                >
                  <div *ngIf="country_id.errors.required">
                    Country is required.
                  </div>
                </div>
              </div>
              <div *ngIf="country_id.value == 1" class="form-group col-sm-8">
                <label for="voivodeshipId">Voivodeship</label>
                <select
                  class="form-control"
                  id="voivodeshipId"
                  formControlName="voivodeship_id"
                >
                  <option value="0">None</option>
                  <option value="1">dolnośląskie</option>
                  <option value="2">kujawsko-pomorskie</option>
                  <option value="3">lubelskie</option>
                  <option value="4">lubuskie</option>
                  <option value="5">łódzkie</option>
                  <option value="6">małopolskie</option>
                  <option value="7">mazowieckie</option>
                  <option value="8">opolskie</option>
                  <option value="9">podkarpackie</option>
                  <option value="10">podlaskie</option>
                  <option value="11">pomorskie</option>
                  <option value="12">śląskie</option>
                  <option value="13">świętokrzyskie</option>
                  <option value="14">warmińsko-mazurskie</option>
                  <option value="15">wielkopolskie</option>
                  <option value="16">zachodniopomorskie</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-6">
                <label for="tel">Tel</label>
                <input
                  type="text"
                  class="form-control"
                  id="tel"
                  formControlName="tel"
                />
              </div>
              <div class="form-group col-sm-6">
                <label for="tel2">Tel2</label>
                <input
                  type="text"
                  class="form-control"
                  id="tel2"
                  formControlName="tel2"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-6">
                <label for="email">email</label>
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  formControlName="email"
                />
              </div>
              <div class="form-group col-sm-6">
                <label for="www">www</label>
                <input
                  type="text"
                  class="form-control"
                  id="www"
                  formControlName="www"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-6 col-lg-3">
                <label for="name">Latitude</label>
                <input
                  type="text"
                  class="form-control"
                  id="latitude"
                  formControlName="latitude"
                />
              </div>
              <div class="form-group col-sm-6 col-lg-3">
                <label for="name">Longitude</label>
                <input
                  type="text"
                  class="form-control"
                  id="longitude"
                  formControlName="longitude"
                />
              </div>
            </div>

            <div class="form-group">
              <div class="d-flex">
                <div>
                  <fieldset>
                    <label>
                      <input
                        type="checkbox"
                        id="is_show_room"
                        name="is_show_room"
                        formControlName="is_show_room"
                      />
                      Show room
                    </label>
                  </fieldset>
                </div>
                <div class="ml-4">
                  <fieldset>
                    <label>
                      <input
                        type="checkbox"
                        id="is_service"
                        name="is_service"
                        formControlName="is_service"
                      />
                      Service
                    </label>
                  </fieldset>
                </div>
                <div class="ml-4">
                  <fieldset>
                    <label>
                      <input
                        type="checkbox"
                        id="is_stock"
                        name="is_stock"
                        formControlName="is_stock"
                      />
                      Stock
                    </label>
                  </fieldset>
                </div>
                <div class="ml-4">
                  <fieldset>
                    <label>
                      <input
                        type="checkbox"
                        id="is_iteam"
                        name="is_iteam"
                        formControlName="is_iteam"
                      />
                      Installation team
                    </label>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-footer">
            <button
              type="button"
              class="btn btn-sm btn-warning mr-1"
              (click)="onCancel()"
            >
              <i class="fa fa-ban"></i> Cancel
            </button>
            <button type="submit" class="btn btn-sm btn-primary">
              <i class="fa fa-dot-circle-o"></i> Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
