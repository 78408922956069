import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {UsersService} from '../users.service';
import {AddUser} from '../add-user.model';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-users-add',
  templateUrl: './users-add.component.html'
})
export class UsersAddComponent implements OnInit {

  userForm: FormGroup;

  constructor(private fb: FormBuilder, private usersService: UsersService, private router: Router,
              private toastr: ToastrService, private spinnerService: NgxSpinnerService, private location: Location) {

  }

  ngOnInit() {
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', [Validators.required,  Validators.minLength(6)]],
      password_confirmation: ['', [Validators.required,  Validators.minLength(6)]],
      notificationsEnabled: true,
      isActive: false,
      isAdmin: false
    });
  }

  onSubmit() {
    if (this.isValidForm()) {

      const formModel = this.userForm.value;
      const user: AddUser = {
        name: formModel.name as string,
        email: formModel.email as string,
        isAdmin: formModel.isAdmin as boolean,
        isActive: formModel.isActive as boolean,
        languageId: 1,
        notificationsEnabled: formModel.notificationsEnabled as boolean,
        password: formModel.password as string,
        password_confirmation: formModel.password_confirmation as string
      };
      this.usersService.addUser(user)
        .subscribe(() => {
          this.toastr.success('User account created successfully');
          this.router.navigate(['./users/list']);
        });
    }
  }

  onCancel() {
    this.location.back();
  }

  isValidForm() {
    return this.userForm.status === 'VALID';
  }

  get name() {
    return this.userForm.get('name');
  }

  get email() {
    return this.userForm.get('email');
  }

  get password() {
    return this.userForm.get('password');
  }

  get password_confirmation() {
    return this.userForm.get('password_confirmation');
  }

}
