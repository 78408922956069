import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Location} from '@angular/common';
import {UsersService} from '../users.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '../../../user/user.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html'
})
export class UsersEditComponent implements OnInit {

  userForm: FormGroup;
  userId: number;
  user: User;

  constructor(private fb: FormBuilder, private usersService: UsersService, private router: Router,
              private toastr: ToastrService, private spinnerService: NgxSpinnerService,
              private location: Location, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.userId = parseInt(params['id']);
      this.usersService.getUser(this.userId).subscribe(
        user => {
          this.user = user;
          this.setFormValues();
        }
      );
    });
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      notificationsEnabled: false,
      isAdmin: false,
      // isActive: false
    });
  }

  onSubmit() {
    if (this.isValidForm()) {

      const formModel = this.userForm.value;
      const user: any = {
        name: formModel.name as string,
        email: formModel.email as string,
        admin: formModel.isAdmin as boolean,
        notificationsEnabled: formModel.notificationsEnabled as boolean
      };
      this.usersService.updateUser(this.userId, user)
        .subscribe(() => {
          this.toastr.success('User account updated successfully');
          this.router.navigate(['./users/list']);
        });
    }
  }

  onCancel() {
    this.location.back();
  }

  isValidForm() {
    return this.userForm.status === 'VALID';
  }

  get name() {
    return this.userForm.get('name');
  }

  get email() {
    return this.userForm.get('email');
  }

  private setFormValues() {
    this.userForm.setValue({
      name: this.user.name as string,
      email: this.user.email as string,
      isAdmin: this.user.isAdmin,
      notificationsEnabled: this.user.notificationsEnabled,
    })
  }
}
