<div class="col-md-12">
    <div class="row">
        Last modified by&nbsp;:&nbsp;<span id="lastInFeatures"></span>
    </div>
</div>
<div class="col-md-12 mt-3">
  <div class="row">
    <div class="col-xl-3 col-lg-4 col-md-6" *ngFor="let prf of productRelatedFeatures | async">
      <app-feature *ngIf="!prf.feature.isRangeFeature"
                   [productRelatedFeature]="prf"
                   (featureItemChanged)="onFeatureItemChange($event)"></app-feature>
      <app-range-feature *ngIf="prf.feature.isRangeFeature"
                         [productRelatedFeature]="prf"
                         (featureItemChanged)="onFeatureItemChange($event)"></app-range-feature>
    </div>
  </div>
</div>
