<form [formGroup]="productForm" (ngSubmit)="onSubmit()" novalidate  #pform="ngForm">
    <div class="animated fadeIn">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        Basic information
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <label for="name">Name</label>
                            <input type="text" class="form-control" id="name" formControlName="name">
                            <div *ngIf="name.invalid && (name.dirty || name.touched || pform.submitted)" class="alert alert-danger">
                                <div *ngIf="name.errors.required">
                                    Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="info">SEO</label>
                            <input type="text" class="form-control" id="info" formControlName="info">
                        </div>
                        <div class="form-group">
                            <label for="shortName">Short name</label>
                            <input type="text" class="form-control" id="shortName" formControlName="shortName">
                        </div>
                        <div class="form-group">
                            <label for="catalogueNumber">Catalogue number</label>
                            <input type="text" class="form-control" id="catalogueNumber" formControlName="catalogueNumber">
                            <div *ngIf="catalogueNumber.invalid && (catalogueNumber.dirty || catalogueNumber.touched || pform.submitted)" class="alert alert-danger">
                                <div *ngIf="catalogueNumber.errors.required">
                                    Catalogue number is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="info">Category</label>
                            <select class="form-control" formControlName="categoryId">
                                <option value="">None</option>
                                <option *ngFor="let category of categories" [value]="category.id">{{category.name}}</option>
                            </select>
                            <div *ngIf="categoryId.invalid && (categoryId.dirty || categoryId.touched || pform.submitted)" class="alert alert-danger">
                                <div *ngIf="categoryId.errors.required">
                                    Category is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="info">Attributes</label>

                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="width: 70%">Name</th>
                                            <th style="width: 30%">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let attribute of attribs">
                                            <td>{{attribute.description}}</td>
                                            <td>
                                                <button type="button" class="btn btn-sm btn-danger" (click)="deleteAttribute(attribute.id)">
                                                    Remove</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="text" class="form-control" formControlName="attribute" />
                                            </td>
                                            <td>
                                                <button type="button" class="btn btn-sm btn-primary" (click)="addAttribute()" [disabled]="productForm.get('attribute').value === ''">
                                                    Add</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--/.col-->
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        Description
                    </div>
                    <div class="card-body">
                        <angular-tinymce formControlName="description" ngDefaultControl></angular-tinymce>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        Application
                    </div>
                    <div class="card-body">
                        <angular-tinymce formControlName="assets"></angular-tinymce>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        Mounting
                    </div>
                    <div class="card-body">
                        <angular-tinymce formControlName="assemblyDescription"></angular-tinymce>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        Additional info
                    </div>
                    <div class="card-body">
                        <angular-tinymce formControlName="additionalInfo"></angular-tinymce>
                    </div>
                </div>

                <div class="card">
                    <div class="card-footer">
                      <button type="reset" class="btn btn-sm btn-danger" (click)="onCancel()">
                        <i class="fa fa-ban"></i> Cancel</button>
                      <button type="submit" class="btn btn-sm btn-primary">
                          <i class="fa fa-dot-circle-o"></i> Submit</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</form>