import {Component, ElementRef, OnInit} from '@angular/core';
import {User} from '../../user/user.model';
import {Observable} from 'rxjs/Observable';
import {UserService} from '../../user/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html'
})
export class AppSidebar implements OnInit {

  user: Observable<User>;

  constructor(private el: ElementRef, private userService: UserService) { }

  ngOnInit(): void {
    const nativeElement: HTMLElement = this.el.nativeElement,
    parentElement: HTMLElement = nativeElement.parentElement;
    // move all children out of the element
    while (nativeElement.firstChild) {
      parentElement.insertBefore(nativeElement.firstChild, nativeElement);
    }
    // remove the empty element(the host)
    parentElement.removeChild(nativeElement);

    this.user = this.userService.getUser();
  }

  isAdmin(): boolean {
    return this.userService.isAdmin();
  }

  isRestricted(): boolean {
    return this.userService.isRestricted();
  }

}
