<div id="fine-uploader-manual-trigger">
    <div id="{{divId}}"></div>
</div>
<br />
<br />
<div *ngIf="image">
    <div class="table-responsive">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th style="width: 20%">Name</th>
                    <th style="width: 80%">Image</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{image.substring(image.lastIndexOf('/')+1)}}</td>
                    <td>
                        <img src="{{image}}" class="main-image-thumbnail">
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>