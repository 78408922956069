<form [formGroup]="lumenOutputRangeForm" (ngSubmit)="onSubmit()" novalidate #dform="ngForm">
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="form-group col-lg-6">
                <label for="name">Name</label>
                <input type="text" class="form-control" id="name" formControlName="name">
                <div *ngIf="name.invalid && (name.dirty || name.touched || dform.submitted)" class="alert alert-danger">
                  <div *ngIf="name.errors.required">
                    Name is required.
                  </div>                
                </div>
                <label for="name">Strip</label>
                <input type="text" class="form-control" id="strip" formControlName="strip">
                <div *ngIf="strip.invalid && (strip.dirty || strip.touched || dform.submitted)" class="alert alert-danger">
                  <div *ngIf="strip.errors.required">
                    Strip is required.
                  </div>                
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-6 mt-2" *ngIf="lumenOutputRange">
                <label>Lumen output range</label>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th style="width: 30%">Name</th>
                        <th style="width: 15%">Min value</th>
                        <th style="width: 15%">Max value</th>
                        <th style="width: 30%">Action</th>
                        <th style="width: 20%">Active</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of lumenOutputRange.items">
                        <td>{{item.name}}</td>
                        <td>{{item.minIntValue}}</td>
                        <td>{{item.maxIntValue}}</td>
                        <td>
                          <button type="button" class="btn btn-sm btn-primary" (click)="openEditItemModal(editItemTemplate, item)">
                            <i class="fa fa-pencil"></i>
                          </button>
                          <button type="button" class="btn btn-sm btn-danger" (click)="opelDeleteItemModal(deleteItemTemplate, item)">
                            <i class="fa fa-trash"></i>
                          </button>
                          <button class="btn btn-sm btn-secondary" type="button" (click)="sortUp(item.id)">
                              <i class="fa fa-chevron-up"></i>
                            </button>
                            <button class="btn btn-sm btn-secondary" type="button" (click)="sortDown(item.id)">
                              <i class="fa fa-chevron-down"></i>
                            </button>
                        </td>
                        <td>
                            <label class="switch switch-lg switch-3d switch-primary">
                                <input type="checkbox" class="switch-input" [checked]="item.isActive" (click)="clickOnisActive($event.target.checked,item.id)">
                                <span class="switch-label"></span>
                                <span class="switch-handle"></span>
                              </label>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="text" class="form-control" formControlName="lumenOutputRangeItems" />
                        </td>
                        <td>
                          <input type="text" class="form-control" formControlName="lumenOutputRangeMinValue" />
                        </td>
                        <td>
                          <input type="text" class="form-control" formControlName="lumenOutputRangeMaxValue" />
                        </td>
                        <td>
                            <button type="button" class="btn btn-sm btn-primary" (click)="addItem()" [disabled]="lumenOutputRangeForm.get('lumenOutputRangeItems').value === ''">
                                Add
                              </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-footer">

            <div class="card-footer">
              <button type="button" class="btn btn-sm btn-warning" (click)="onCancel()">
                <i class="fa fa-ban"></i> Cancel</button>
              <button type="submit" class="btn btn-sm btn-primary">
                <i class="fa fa-dot-circle-o"></i> Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<ng-template #editItemTemplate>
  <div class="modal-header">
    <h4 class="modal-title">Confirm</h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="editItemForm">
      <div class="form-group">
        <label for="name">Lumen output range item</label>
        <input type="text" class="form-control" formControlName="name">
      </div>

      <div class="form-group">
        <label for="name">Min value</label>
        <input type="text" class="form-control" formControlName="minIntValue">
      </div>
      <div class="form-group">
        <label for="name">Max value</label>
        <input type="text" class="form-control" formControlName="maxIntValue">
      </div>
    </form>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="declineItemEdit()">No</button>
    <button type="button" class="btn btn-primary" (click)="confirmItemEdit()">Yes</button>
  </div>
</ng-template>

<ng-template #deleteItemTemplate>
  <div class="modal-header">
    <h4 class="modal-title">Confirm</h4>
  </div>
  <div class="modal-body">
    <p>Do you want to delete this Lumen output range item?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="declineItemDelete()">No</button>
    <button type="button" class="btn btn-primary" (click)="confirmItemDelete()">Yes</button>
  </div>
</ng-template>
