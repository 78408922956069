<div id="qq-template" hidden>
  <div class="qq-uploader-selector qq-uploader" qq-drop-area-text="Drop files here">
    <div class="qq-total-progress-bar-container-selector qq-total-progress-bar-container">
      <div role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
           class="qq-total-progress-bar-selector qq-progress-bar qq-total-progress-bar"></div>
    </div>
    <div class="qq-upload-drop-area-selector qq-upload-drop-area" qq-hide-dropzone>
      <span class="qq-upload-drop-area-text-selector"></span>
    </div>
    <div class="buttons">
      <div class="qq-upload-button-selector qq-upload-button">
        <div>Select files</div>
      </div>
      <!-- <button type="button" id="trigger-upload" class="btn btn-primary">
                  <i class="icon-upload icon-white"></i> Upload
              </button> -->
    </div>
    <span class="qq-drop-processing-selector qq-drop-processing">
            <span>Processing dropped files...</span>
            <span class="qq-drop-processing-spinner-selector qq-drop-processing-spinner"></span>
        </span>
    <ul class="qq-upload-list-selector qq-upload-list" aria-live="polite" aria-relevant="additions removals">
      <li>
        <div class="qq-progress-bar-container-selector">
          <div role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
               class="qq-progress-bar-selector qq-progress-bar"></div>
        </div>
        <span class="qq-upload-spinner-selector qq-upload-spinner"></span>
        <img class="qq-thumbnail-selector" qq-max-size="100" qq-server-scale>
        <span class="qq-upload-file-selector qq-upload-file"></span>
        <span class="qq-edit-filename-icon-selector qq-edit-filename-icon" aria-label="Edit filename"></span>
        <input class="qq-edit-filename-selector qq-edit-filename" tabindex="0" type="text">
        <span class="qq-upload-size-selector qq-upload-size"></span>
        <span role="status" class="qq-upload-status-text-selector qq-upload-status-text"></span>
      </li>
    </ul>
  </div>
</div>

<div class="animated fadeIn" id="top">
  <div class="row" *ngIf="product" >
    <div class="col-12"><h4>{{product.name}} - {{product.catalogueNumber}}</h4></div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <!-- Nav tabs -->
      <tabset>
        <tab heading="Product information">
          <div class="col-md-12">
              <div class="row mr-2">
                  <div class="col">
                      Last modified by&nbsp;:&nbsp;<span id="lastInUpdates"></span>
                  </div>
                </div>
            </div>
          <form [formGroup]="productForm" (ngSubmit)="onSubmit()" novalidate #pform="ngForm">
            <div class="col-md-12 mt-3">
              <div class="row">
                <div class="col mb-2">
                  <span class="mr-2">Active </span>
                  <label class="switch switch-lg switch-3d switch-primary" >
                    <input type="checkbox" class="switch-input" formControlName="isActive" (change)="changeActiveFlag($event)">
                    <span class="switch-label"></span>
                    <span class="switch-handle"></span>
                  </label>
                  <span class="mr-2 ml-2">Virtual Product </span>
                  <label class="switch switch-lg switch-3d switch-primary" >
                    <input type="checkbox" class="switch-input" formControlName="isVirtualProduct" >
                    <span class="switch-label"></span>
                    <span class="switch-handle"></span>
                  </label>
                  <span class="mr-2 ml-2">Display on list</span>
                  <label class="switch switch-lg switch-3d switch-primary" >
                    <input type="checkbox" class="switch-input" formControlName="isVisibleOnList" >
                    <span class="switch-label"></span>
                    <span class="switch-handle"></span>
                  </label>
                  <span class="mr-2 ml-2">Hide on search bar</span>
                  <label class="switch switch-lg switch-3d switch-primary" >
                    <input type="checkbox" class="switch-input" formControlName="inVisibleOnSearch" >
                    <span class="switch-label"></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
                <div class="col mb-2" *ngIf="product">
                  <a class="btn btn-sm btn-primary float-right  mr-2" target="_blank" href="{{contentUrl + '/product/' + product.productGuid}}">
                    <i class="fa fa-search-plus"></i>
                  </a>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  Basic information
                  <button type="submit" class="btn btn-sm btn-primary float-right" >
                    <i class="fa fa-save"></i> Submit
                  </button>
                  <button *ngIf="product && !product.isVirtualProduct" type="button" class="btn btn-sm btn-warning float-right mr-2" style="color: white !important;" (click)="copyProduct()">
                    <i class="fa fa-copy"></i> Copy
                  </button>
                  <button type="button" class="btn btn-sm btn-secondary float-right  mr-2" [routerLink]="['/products/list']">
                    <i class="fa fa-arrow-left"></i> Product list
                  </button>
                </div>
                <div class="card-body">
                  <div class="form-group">
                    <label for="name">Name</label>
                    <input type="text" class="form-control" id="name" formControlName="name">
                    <div *ngIf="name.invalid && (name.dirty || name.touched || pform.submitted)" class="alert alert-danger">
                      <div *ngIf="name.errors.required">
                        Name is required.
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="info">SEO</label>
                    <input type="text" class="form-control" id="info" formControlName="info">
                  </div>
                  <div class="form-group">
                    <label for="shortName">Short name</label>
                    <input type="text" class="form-control" id="shortName" formControlName="shortName">
                  </div>
                  <div class="form-group">
                    <label for="catalogueNumber">Catalogue number</label>
                    <input type="text" class="form-control" id="catalogueNumber" formControlName="catalogueNumber"/>
                    <div *ngIf="catalogueNumber.invalid && (catalogueNumber.dirty || catalogueNumber.touched || pform.submitted)" class="alert alert-danger">
                      <div *ngIf="catalogueNumber.errors.required">
                        Catalogue number is required.
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="info">Category</label>
                    <select class="form-control" formControlName="categoryId">
                      <option value="">None</option>
                      <option *ngFor="let category of categories" [value]="category.id">{{category.name}}</option>
                    </select>
                    <div *ngIf="categoryId.invalid && (categoryId.dirty || categoryId.touched || pform.submitted)" class="alert alert-danger">
                      <div *ngIf="categoryId.errors.required">
                        Category is required.
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="info">Video instruction link</label>
                    <input type="text" class="form-control" id="video_instruction_link" formControlName="instructionalVideo">
                  </div>
                  <div class="form-group">
                    <label for="info">Video instruction link 2 (For system led use)</label>
                    <input type="text" class="form-control" id="video_instruction_link_2" formControlName="instructionalVideo2">
                  </div>
                  <div class="row">
                    <div class="form-group col-lg-4 ">
                      <label for="promotedUntil">Promoted until</label>
                      <div class="input-group">
                        <input type="text" class="form-control" id="promotedUntil" #dppu="bsDatepicker" id="promotedUntil" placeholder="yyyy-mm-dd"
                               [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
                               formControlName="promotedUntil"
                               bsDatepicker>
                        <span class="input-group-addon"  (click)="dppu.toggle()"><i class="fa fa-calendar"></i></span>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div class="col-md-12">
              <div class="card" >
                <div class="card-header">
                  Description
                </div>
                <div class="card-body">
                  <angular-tinymce formControlName="description"></angular-tinymce>
                </div>
              </div>

              <div class="card" [style.display]="'inherit'">
                <div class="card-header">
                  Application
                </div>
                <div class="card-body">
                  <angular-tinymce formControlName="assets"></angular-tinymce>
                </div>
              </div>

              <div class="card" [style.display]="'inherit'">
                <div class="card-header">
                  Mounting
                </div>
                <div class="card-body">
                  <angular-tinymce formControlName="assemblyDescription"></angular-tinymce>
                </div>
              </div>

              <div class="card" [style.display]="'inherit'">
                <div class="card-header">
                  Additional info
                </div>
                <div class="card-body">
                  <angular-tinymce formControlName="additionalInfo"></angular-tinymce>
                </div>
              </div>

              <div class="card">
                <div class="card-footer">
                  <button type="button" class="btn btn-sm btn-secondary mr-1" [routerLink]="['/products/list']">
                    <i class="fa fa-arrow-left"></i> Product list
                  </button>
                  <button type="submit" class="btn btn-sm btn-primary" >
                    <i class="fa fa-save"></i> Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </tab>
        <tab heading="Product specification">
          <app-product-additional-data [product]="product" [productId]="productId" [advanceTemplate]="product"></app-product-additional-data>
        </tab>
        <tab heading="Album - Products">
          <edit-product-album-images *ngIf="productId && product && product.productImages" divId="album-products"
                                     [images]="product.productImages" [productId]="productId"  fileType="1">
          </edit-product-album-images>
        </tab>

        <tab heading="Album - Usage" customClass="">
          <edit-product-album-images *ngIf="productId && product && product.usageImages" divId="album-usage"
                                     [images]="product.usageImages" [productId]="productId"  fileType="2">
          </edit-product-album-images>
        </tab>

        <tab heading="Documents">
          <album-documents *ngIf="productId && product && product.documents" divId="album-documents"
            [productCard]="!product.preventProductCardGeneration"
            [images]="product.documents"
            [productId]="productId" fileType="3">
          </album-documents>
        </tab>

        <tab heading="Features">
          <app-product-features (featuresChanged) ="onFeaturesChanged($event)" *ngIf="product && productId != null" [productId]="productId" [productCategoryId]="product.categoryId"
                                [featureItems]="product.featureItems">
          </app-product-features>
        </tab>
        <tab heading="Related products">
          <edit-product-related-products *ngIf="product && productId != null"
                                         [productId]="productId"
                                         [productName]="product.name"
                                         [productCategoryId]="product.categoryId"
                                         [productParentId]="product.parentId"
                                         [childs]="product.childs">
          </edit-product-related-products>
        </tab>

      </tabset>
    </div>
  </div>
</div>

