<div id="fine-uploader-manual-trigger">
    <div class="col mb-2" *ngIf="fileType==3" (ngInit)="reloadLastModifieds()">
        Last modified by&nbsp;:&nbsp;<span id="lastInCouplersAndAuto"></span>
        <br>
        <br>
        <span class="mr-2">Auto generated product card </span>
        <label class="switch switch-lg switch-3d switch-primary">
            <input type="checkbox" class="switch-input" value="true" [checked]="productCard" (change)="preventProductCardGenerationChange($event)">
            <span class="switch-label"></span>
            <span class="switch-handle"></span>
        </label>
    </div>
    <div *ngIf="fileType==1" (ngInit)="reloadLastModifieds()">
            Last modified by&nbsp;:&nbsp;<span id="lastInAlbumProducts"></span>
        </div>
        <div *ngIf="fileType==2" (ngInit)="reloadLastModifieds()">
            Last modified by&nbsp;:&nbsp;<span id="lastInAlbumUsages"></span>
        </div>
        <div *ngIf="fileType==3" (ngInit)="reloadLastModifieds()">
            Last modified by&nbsp;:&nbsp;<span id="lastInAlbumDocuments"></span>
        </div>
    <div id="{{divId}}"></div>
</div>
<br />
<br />
<div *ngIf="images">
    <div class="table-responsive">
        <table class="table table-bordered" *ngIf="images.length > 0">
            <thead>
                <tr>
                    <th style="width: 5%">Id</th>
                    <th style="width: 35%">Name</th>
                    <th style="width: 20%">Image</th>
                    <th style="width: 40%">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let image of images; let i = index">
                    <td>{{image.id}}</td>
                    <td>{{image.name}}</td>
                    <td>
                        <img src="{{image.thumbFilename}}" class="image-thumbnail">

                    </td>
                    <td>
                        <a class="btn btn-sm btn-primary mr-1" href="{{image.filename}}" target="_blank">
                            <i class="fa fa-search"></i>
                        </a>
                        <button class="btn btn-sm btn-primary mr-1" type="button" (click)="openEditModal(editTemplate, image.id, image.name)">
                            <i class="fa fa-pencil"></i>
                        </button>
                        <button class="btn btn-sm btn-danger mr-1" type="button" (click)="openDeleteModal(deleteTemplate, image.directoryGuid)">
                            <i class="fa fa-trash"></i>
                        </button>
                        <button *ngIf="i != 0" class="btn btn-sm btn-primary mr-1" type="button" (click)="moveBefore(i)">
                            <i class="fa fa-chevron-up"></i>
                        </button>
                        <button *ngIf="i < images.length -1 " class="btn btn-sm btn-primary mr-1" type="button" (click)="moveAfter(i)">
                            <i class="fa fa-chevron-down"></i>
                        </button>

                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #deleteTemplate>
    <div class="modal-header">
        <h4 class="modal-title">Confirm</h4>
    </div>
    <div class="modal-body">
        <p>Do you want to delete this file?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="declineDelete()">No</button>
        <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
    </div>
</ng-template>

<ng-template #editTemplate>
    <div class="modal-header">
        <h4 class="modal-title">Confirm</h4>
    </div>
    <div class="modal-body">
        <form [formGroup]="editFileForm">
            <div class="form-group">
                <label>File name</label>
                <input type="text" class="form-control" formControlName="name">
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="declineEdit()">No</button>
        <button type="button" class="btn btn-primary" (click)="confirmEdit()">Yes</button>
    </div>
</ng-template>