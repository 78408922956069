import {merge as observableMerge, fromEvent as observableFromEvent, Observable, BehaviorSubject} from 'rxjs';

import {map, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {DistributorService} from '../distributor.service';
import {Distributor} from '../Distributor.model';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {ToastrService} from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
})
export class UserListComponent implements OnInit {

  displayedColumns = ['id','company', 'name', 'address', 'city', 'country', 'type', 'active','is_visible_www', 'actions'];
  dataSource: DistributorsDatasource | null;
  database: DistributorDatabase;
  modalRef: BsModalRef;
  distributorId: number;
  typeFilterActive = false;

  @ViewChild('filter', {static: true}) public filter: ElementRef;
  @ViewChild(MatSort, {static: true}) public sort: MatSort;
  @ViewChild(MatPaginator,{static: true}) public paginator: MatPaginator;

  constructor(private toastr: ToastrService, private router: Router,
              private distributorService: DistributorService, private modalService: BsModalService) {

  }

  ngOnInit(): void {
    this.database = new DistributorDatabase(this.distributorService);
    this.dataSource = new DistributorsDatasource(this.database, this.paginator, this.sort)

    observableFromEvent(this.filter.nativeElement, 'keyup').pipe(
      debounceTime(150),
      distinctUntilChanged(),)
      .subscribe(() => {
        if (!this.dataSource) { return; }
        this.dataSource.filter = this.filter.nativeElement.value;
      });
  }


  redirectToEdit(distributorId) {
    this.router.navigate(['./distributors/user/edit/' + distributorId]);
  }

  openModal(template: TemplateRef<any>, distributorId: number) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.distributorId = distributorId;
  }

  confirm(): void {
    this.distributorService.deleteUser(this.distributorId)
      .subscribe(
        response => {
            this.toastr.success('Distributor removed successfully', '');
            this.deleteRowDataTable(this.distributorId);
        });
    this.modalRef.hide();
  }

  decline(): void {
    this.distributorId = null;
    this.modalRef.hide();
  }

  private deleteRowDataTable (itemId) {
    const dsData = this.database.data;
    const itemIndex = dsData.findIndex(obj => obj['id'] === itemId);
    dsData.splice(itemIndex, 1)
    this.database.dataChange.next(dsData);
  }

  onTypeChange(typeId) {
    this.dataSource.typeFilter = parseInt(typeId);
    this.typeFilterActive = this.dataSource.typeFilter !== -1;
  }
}

export class DistributorDatabase {
  /** Stream that emits whenever the data has been modified. */
  dataChange: BehaviorSubject<Distributor[]> = new BehaviorSubject<Distributor[]>([]);
  get data(): Distributor[] { return this.dataChange.value; }

  constructor(private distributorService: DistributorService) {
    distributorService.getDistributorsUsers().subscribe(
      distributors => {
        this.dataChange.next(distributors);
      });
  }
}


export class DistributorsDatasource extends DataSource<Distributor> {
  _filterChange = new BehaviorSubject('');
  get filter(): string { return this._filterChange.value; }
  set filter(filter: string) { this._filterChange.next(filter); }
  _typeFilterChange = new BehaviorSubject(-1);

  get typeFilter(): number {
    return this._typeFilterChange.value || -1;
  }

  set typeFilter(typeId: number) {
    this._typeFilterChange.next(typeId);
  }
  prevFilterValue: string;

  constructor(private _database: DistributorDatabase, private _paginator: MatPaginator, private _sort: MatSort) {
    super();
    this.prevFilterValue = this.filter.toLowerCase();
  }

  connect(collectionViewer: CollectionViewer): Observable<Distributor[]> {
    const displayDataChanges = [
      this._database.dataChange,
      this._filterChange,
      this._sort.sortChange,
      this._typeFilterChange,
      this._paginator.page
    ];
    return observableMerge(...displayDataChanges).pipe(map(() => {

      const data = this._database.data.slice().filter((item: Distributor) => {
        if (item.name == null) {
          return false;
        }
        const searchStr = (item.name).toLowerCase();
        const searchResult = searchStr.indexOf(this.filter.toLowerCase()) !== -1;
        const typeResult = this.typeFilter === -1 || item.type === this.typeFilter;
        return searchResult && typeResult;
      });

      if (this.filter.toLowerCase() !== this.prevFilterValue) {
        this._paginator.pageIndex = 0;
      }
      this.prevFilterValue = this.filter.toLowerCase();

      // Sort filtered data
      const sortedData = this.sortData(data.slice());

      // Grab the page's slice of data.
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return sortedData.splice(startIndex, this._paginator.pageSize);
    }));
  }

  disconnect(collectionViewer: CollectionViewer): void {
  }
  /** Returns a sorted copy of the database data. */
  sortData(data: Distributor[]): Distributor[] {
    if (!this._sort.active || this._sort.direction === '') { return data; }

    return data.sort((a, b) => {
      let propertyA: number|string = '';
      let propertyB: number|string = '';

      switch (this._sort.active) {
        case 'id': [propertyA, propertyB] = [a.id, b.id]; break;
        case 'name': [propertyA, propertyB] = [a.name, b.name]; break;
        case 'city': [propertyA, propertyB] = [a.name, b.name]; break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }
}
